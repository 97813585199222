import Papa from "papaparse";
const exportToCSV = (data) => {
  const csv = Papa.unparse(data);
  console.log("csv data", csv);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "reelax.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default exportToCSV;
