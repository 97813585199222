import React, { useState } from "react";
import {
  CustomText,
  Image,
  CustomTextInput,
  CustomTextArea,
  PrimaryCta,
  TeamMembers,
} from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useSelector } from "react-redux";
import Utils from "../../common/utils";
import Icon from "../../components/core_component/icon";
import Core from "../../common/clientSdk";
import validator from "validator";
import { useNavigate } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { useSnackbar } from "react-simple-snackbar";

function UserChangePassword() {
  const [isLoading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openStatus, setOpenStatus] = useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [error, set_error] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const navigate = useNavigate();
  function postChangePassword(progress_cb) {
    const params = {
      old_password: oldPassword,
      new_password: newPassword,
    };

    if (
      validateOldPassword(newPassword) &&
      validateNewPassword(oldPassword) &&
      showError()
    )
      Core.postChangePassword(
        postChangePasswordSuccess,
        postChangePasswordFailed,
        progress_cb,
        params
      );
  }

  function postChangePasswordSuccess(response_data) {
    openSnackbar("Password Changed Successfully!");
    setLoading(false);
    navigate("/user-settings");
  }

  function postChangePasswordFailed(errorList) {
    openSnackbar(errorList);
  }

  function updateProgress(status) {
    setLoading(status);
  }

  function showError() {
    if (newPassword != confirmPassword) {
      openSnackbar("Confirm password should be same");
      return false;
    }
    return true;
  }

  function validateOldPassword(value) {
    if (!validator.isLength(value, { min: 8 })) {
      set_error({
        ...error,
        old_password: "Required length 8 ",
      });
    } else {
      set_error({ ...error, old_password: "" });
      return true;
    }
  }
  function validateNewPassword(value) {
    if (!validator.isLength(value, { min: 8 })) {
      set_error({
        ...error,
        new_password: "Required length 8 ",
      });
    } else {
      set_error({ ...error, new_password: "" });
      return true;
    }
  }
  function validateConfirmPassword(value) {
    if (!validator.isLength(value, { min: 8 })) {
      set_error({
        ...error,
        confirm_password: "Required length 8 ",
      });
    } else {
      set_error({ ...error, confirm_password: "" });
      return true;
    }
  }
  return (
    <div className="dashboard-container">
      <SideMenu />

      <div className="main-screen">
        <Header />
        <div className="page-container">
          <div className="campaign-text-heading">
            <CustomText
              p
              text={"User Settings"}
              textColor={"text_color_light"}
              fontSize={"font_size_2xl"}
            />
          </div>

          <div className="setting-screen-main-container content-row-parent">
            <div className="m-t-15 full-width">
              <CustomTextInput
                type="password"
                value={oldPassword}
                placeholder="Old Password"
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
                onBlur={(e) => validateOldPassword(e.target.value)}
              />

              <p className="error">{error.old_password}</p>
            </div>
            <div className="m-t-15 full-width">
              <CustomTextInput
                type="password"
                value={newPassword}
                placeholder="New Password"
                onChange={(e) => setNewPassword(e.target.value)}
                onBlur={(e) => validateNewPassword(e.target.value)}
              />
              <p className="error">{error.new_password}</p>
            </div>
            <div className="m-t-15 full-width">
              <CustomTextInput
                type="password"
                value={confirmPassword}
                placeholder="Confirm Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                onBlur={(e) => validateConfirmPassword(e.target.value)}
              />
              <p className="error">{error.confirm_password}</p>
            </div>
            <div className="m-t-15 full-width">
              <PrimaryCta
                fontSize={"font_size_large"}
                fullWidth={true}
                text={"Change Password"}
                isLoading={isLoading}
                disabled={Utils.isNull(newPassword)}
                onClick={() => {
                  if (newPassword === confirmPassword) {
                    postChangePassword(updateProgress);
                  } else {
                    openSnackbar("Confirm password should be the same");
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserChangePassword;
