import React, { useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomText,
  CustomTextInput,
  CustomTextArea,
  PrimaryCta,
} from "../../components";
import Utils from "../../common/utils";
import Core from "../../common/clientSdk";
import { useState } from "react";
import { useEffect } from "react";
import validator from "validator";
import { Loader } from "../loader";
import { useNavigate } from "react-router-dom";
import * as Actions from "../../redux/actions";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-dropdown-select";
import * as CountryCode from "../../common/data/cuntries_code";
import Multiselect from "multiselect-react-dropdown";
import Style from "../../resources/style";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
function NotificationSetting() {
  const [whatsapp, setWhatsapp] = useState("");
  const [orgData, setOrgData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [pan, setPan] = useState("");
  const [gst, setGst] = useState("");
  const [company, setCompany] = useState("");
  const [countryCode, setCountryCode] = useState([]);
  const [error, set_error] = useState({
    whatsapp: "",
  });

  const redux_dispatch = useDispatch();

  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;

  const { brand_deep_data = {} } = BrandUser;
  const { org_deep_data = {} } = BrandUser;
  const { org_data = {} } = org_deep_data;
  const { brand_data = {} } = brand_deep_data;

  useEffect(() => {
    getOrgDetails();
  }, []);
  useEffect(() => {
    setCompany(org_data.name);
    setGst(org_data?.gst_number);
    setPan(org_data?.pan_number);
    setWhatsapp("+" + org_data?.wa_number);
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  function getOrgDetails() {
    const params = {
      org_id: brand_data.org_id,
    };
    Core.getOrgUserDetails(
      getOrgDetailsSuccess,
      getOrgDetailsFailed,
      () => {},
      params
    );
  }

  function getOrgDetailsSuccess(response_data) {
    setOrgData(response_data.org_data.org_data);
  }

  function getOrgDetailsFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function postUpdateUserOrg(progress_cb) {
    const params = {
      org_id: brand_data.org_id,

      wa_number: removePlusSign(whatsapp),
    };

    if (validateWhatsapp(whatsapp)) {
      Core.postUpdateUserOrg(
        postUpdateUserOrgSuccess,
        postUpdateUserOrgFailed,
        progress_cb,
        params
      );
    }
  }

  function postUpdateUserOrgSuccess(response_data) {
    toast.success("Whatsapp number updated");
    redux_dispatch({
      type: Actions.UPDATE_ORG_DATA,
      payload: {
        org_deep_data: {
          org_data: {
            pan_number: pan,
            gst_number: gst,
            name: company,
            wa_number: removePlusSign(whatsapp),
          },
        },
      },
    });

    setLoading(false);
    getOrgDetails();
  }

  function postUpdateUserOrgFailed(errorList) {
    //openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function removePlusSign(phoneNumber) {
    // Check if the phone number starts with a '+'
    if (whatsapp) {
      if (phoneNumber.startsWith("+")) {
        // Return the substring starting from index 1 (excluding the '+')
        return phoneNumber.substring(1);
      }
    }

    // If no '+' is found, return the original phone number
    return phoneNumber;
  }
  console.log("whatsapp", whatsapp);

  function validateWhatsapp(value) {
    if (!whatsapp) {
      set_error({ ...error, whatsapp: "Please enter Whatsapp Number" });
      toast.info("Please enter Whatsapp Number");
      return false;
    } else if (whatsapp.length < 10) {
      set_error({
        ...error,
        whatsapp: "Please enter 10 digit Whatsapp Number",
      });
      toast.info("Please enter 10 digit Whatsapp Number");
      return false;
    } else {
      set_error({ ...error, whatsapp: "" });
      return true;
    }
  }

  function updateCountryCode(selectedItem) {
    var value = [];
    selectedItem.forEach((item) => {
      value.push(item.calling_code);
    });

    setCountryCode(value);
  }

  console.log("countryCode", countryCode);

  let allCode = CountryCode.countries;
  console.log("whatsapp", whatsapp);
  return (
    <>
      <>
        <div
          style={{ width: 320 }}
          className="setting-screen-main-container brand-setting-container m-b-16"
        >
          <div>
            {/* <div className="m-b-4">
              <CustomText
                h5
                fontSize={"font_size_large"}
                text={`Notification`}
                textColor={"text_grey_400_color"}
                fontWeight={"Poppins_regular_large"}
                height={"line_height_19_2"}
              />
            </div> */}

            <div className=" full-width">
              <div className="m-b-4">
                <CustomText
                  h5
                  fontSize={"font_size_large"}
                  text={`Whatsapp Number`}
                  textColor={"text_grey_400_color"}
                  fontWeight={"Poppins_regular_large"}
                  height={"line_height_19_2"}
                />
              </div>
              <div className="whatsapp-number">
                <PhoneInput
                  defaultCountry="IN"
                  value={whatsapp}
                  onChange={setWhatsapp}
                  placeholder="Enter phone number"
                />
              </div>
              {/* <Multiselect
                placeholder="Choose age group"
                id="css_custom"
                options={allCode}
                onSelect={(selectedList, selectedItem) =>
                  updateCountryCode(selectedList, selectedItem)
                }
                onRemove={(selectedList, selectedItem) =>
                  updateCountryCode(selectedList, selectedItem)
                }
                singleSelect={false}
                selectionLimit={1}
                showArrow={false}
                displayValue={"label"}
                style={{
                  chips: Style.chips,
                  option: Style.option,
                  optionContainer: Style.optionContainer,
                  multiselectContainer: Style.multiselectContainerAddress,
                  searchBox: Style.searchBoxAddress,
                  inputField: Style.inputFieldAddress,
                }}
              /> */}

              {/* <CustomTextInput
                className="update_input_text m-t-12"
                type="number"
                placeholder="Whatsapp number with country code"
                value={whatsapp}
                onChange={(e) => setWhatsapp(e.target.value)}
                onBlur={(e) => validateWhatsapp(whatsapp)}
              /> */}
            </div>

            <div className="m-t-16">
              <PrimaryCta
                fontSize={"font_size_large"}
                fullWidth={true}
                isLoading={isLoading}
                text={"Save Number"}
                onClick={() => postUpdateUserOrg(updateProgress)}
                disabled={isLoading ? true : false}
              />
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default NotificationSetting;
