import React from "react";
import * as svg from "../assets/icons.js";

var SvgIcon;

const Icon = function (props) {
  SvgIcon = svg[props.iconName];

  const { height, width, ...rest } = props;

  return (
    <SvgIcon {...rest} color={props.color} width={width} height={height} />
  );

  
};

export default Icon;
