"use-strict";

/**
 * @fc  - Foreground Color
 * @tc - Text Color
 * @bc - Background Color
 */

const Theme = {
  dimensions: {
    TAG: 8,
    EXTRA_SMALL: 10,
    LABEL: 12,
    SMALL: 13, // LABEL
    PARAGRAPH: 14, // PARAGRAPH
    REGULAR: 14, // PARAGRAPH
    MEDIUM: 15,
    LARGE: 16,
    EXTRA_LARGE: 17,
    XL: 18,
    H3: 20,
    H2: 24, // fixed for this project
    H_25: 25,
    XL_28: 28,
    XL_26: 26,
    H1: 26, // fixed for this project
    H4: 12,
    H5: 10,
    max_size: 50,
  },

  colors: {
    //  Foreground Color
    FC_PRIMARY: "#271F3D", // input Box
    FC_SECONDARY: "#4A4A4A", //border
    FC_THIRD: "#7A2BFB", // submit button
    FC_FOURTH: "#1977F2", // register button

    FC_FIFTH: "#B3B3B6",

    FC_LIGHT: "#FFFFFF",
    FC_SEMI_LIGHT: "#CD212280",
    FC_DISABLED: "#C0C0C0",
    FC_SUCCESS: "#02AD45",
    FC_ERROR: "#CB3B22",
    FC_DANGER: "#DD3732",

    // Primary Colors.
    TC_PRIMARY: "#090A14", // gray 500
    TC_SECONDARY: "#8E8E93", // gray 200
    TC_TERTIARY: "#333333",
    TC_FORGOT: "#B0B0B0",
    TC_GREY_100: "#B33B6",
    TC_ACTIVE: "#E89822",
    TC_PENDING: "#FF4444",
    TC_COMPLETED: "#68D184",

    TC_CREDIT: "#5AA95E",
    TC_DEBIT: "#D95644",

    TC_LIGHT: "#FFFFFF",
    TC_DISABLED: "#C0C0C0",
    TC_SEMI_LIGHT: "#2020201A",
    TC_ERROR: "#FC5151",
    TC_LIGHT_WEIGHT: "#A6A5B4",
    TC_BACK: "#090A14",
    GREY_400: "#3A3B43",
    GREY_50: "#E6E7E8",
    TC_GREY: "#5A5B62",
    TC_RED: "#F04248",
    TC_GREEN: "#009A29",

    // background Color
    BC_BLUE_500: "#1977f2",
    BC_PRIMARY: "#1D162D",
    BC_SECONDARY: "#161023",
    BC_CARD: "#FFFFFF",

    BC_SEMI_LIGHT: "#FFFFFF4D",
    BC_TABLE_CARD: "#1C1835",
    BC_LIGHT: "#FFFFFF",
  },
  fonts: {
    BOLD: "Inter-Bold",
    BOLD_ITALIC: "Poppins-BoldItalic",
    EXTRA_BOLD: "Inter-ExtraBold",
    EXTRA_BOLD_ITALIC: "Poppins-ExtraBoldItalic",
    LIGHT: "Inter-Light",
    LIGHT_ITALIC: "Poppins-LightItalic",
    REGULAR: "Inter-Regular",
    REGULAR_ITALIC: "Poppins-RegularItalic",
    SEMI_BOLD: "Inter-SemiBold",
    SEMI_BOLD_ITALIC: "Poppins-SemiBoldItalic",
    MEDIUM: "Inter-Medium",
  },

  ACTION_BAR_TYPE: {
    PRIMARY_ACTION_BAR: "PRIMARY_ACTION_BAR",
    SECONDARY_ACTION_BAR: "SECONDARY_ACTION_BAR",
    NO_ACTION_BAR: "NO_ACTION_BAR",
  },
};

export default Theme;
