"use-strict";

//Dependencies
import React, { useState, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomText,
  Image,
  CustomTextInput,
  PrimaryCta,
  LinkCta,
  UnderLinedLinkCta,
} from "../../components";

const PrivacyPolicyScreen = function (props) {
  return (
    <div className="main-container">
      <div className="text-screen-container">
        <h1>We value your privacy</h1>
        <p>
          We are committed to protecting your personal information. This policy
          outlines how we collect, use, and share your information when you use
          our app.
        </p>

        <h3>Information Collection</h3>
        <p>
          We collect personal information such as your name, email address, and
          other required information to create an account on our app.
        </p>

        <h3>Information Use</h3>
        <p>
          We use your personal information to facilitate the services offered by
          our app, such as connecting brands with influencers and processing
          payments.
        </p>

        <h3>Information Sharing</h3>
        <p>
          We may share your personal information with third-party service
          providers who assist us in providing our services. We may also share
          your information with legal authorities if required by law.
        </p>

        <h3>Security</h3>
        <p>
          We take reasonable measures to protect your personal information from
          unauthorized access, use, or disclosure.
        </p>

        <h3>Cookies</h3>
        <p>
          We use cookies to improve the user experience on our app and to
          collect analytics data. You can choose to disable cookies in your
          browser settings.
        </p>

        <h3>Data Retention</h3>
        <p>
          We retain your personal information as long as necessary to provide
          our services and as required by law.
        </p>

        <h3>Children's Privacy</h3>
        <p>
          Our app is not intended for children under 13 years of age, and we do
          not knowingly collect personal information from children under 13.
        </p>

        <h3>Modifications</h3>
        <p>
          We reserve the right to modify or update this privacy policy at any
          time. Your continued use of our app after such changes will constitute
          your acceptance of the updated policy. If you have any questions or
          concerns about our terms or privacy policy, please contact us at{" "}
          <a href="mailto:support@getreelax.com">support@getreelax.com</a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyScreen;
