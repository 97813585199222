import React, { useState, useEffect } from "react";
import {
  CustomText,
  FallBack,
  PrimaryCta,
  TeamMembers,
  TransitionHistory,
} from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useNavigate } from "react-router-dom";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";
import { useSnackbar } from "react-simple-snackbar";
import ReportSidebar from "../report-sidebar";
function TeamMembersList() {
  const [memberList, setMemberList] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [openSnackbar] = useSnackbar();

  useEffect(() => {
    getMembersList(updateProgress);
  }, []);

  function updateProgress(status) {}

  function getMembersList(progress_cb) {
    var params = {};
    Core.getMembersList(
      getMembersListSuccess,
      getMembersListFailed,
      progress_cb,
      params
    );
  }

  function getMembersListSuccess(response_data) {
    setMemberList(response_data);
    setLoading(false);
  }

  function getMembersListFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  var navigate = useNavigate();
  return (
    <>
      <div className="dashboard-container">
        <SideMenu />
        <div className="main-screen">
          <Header />
          <div className="page-container ">
            {isLoading ? (
              <div className="loader-mid">
                <Loader />
              </div>
            ) : (
              <>
                <div className="team-member-header">
                  <CustomText
                    p
                    text={"Team Members"}
                    textColor={"text_color_primary"}
                    fontSize={"font_size_2xl"}
                  />
                  <PrimaryCta
                    third
                    fontSize={"font_size_regular"}
                    className="create-campaign"
                    text={"Invite Member"}
                    onClick={() => navigate("/invite-member")}
                  />
                </div>
                {memberList.members_data &&
                memberList.members_data.length == 0 ? (
                  <FallBack
                    heading={"No Member available"}
                    title={"Active member will be shown here"}
                    // subTitle={"elit, sed do eiusmod tempor incididunt. ipasum"}
                  />
                ) : (
                  <div class="content-row-parent">
                    {memberList.members_data &&
                      memberList?.members_data.map((item) => {
                        return (
                          <div className="content-row">
                            <TeamMembers
                              invert
                              source={require("../../resources/images/fallback.png")}
                              Name={item.name}
                              title={"Member"}
                              Email={item.email}
                              stateColor={""}
                              // state={"ACTIVE"}
                              // iconName={"DeleteIcons"}
                            />
                          </div>
                        );
                      })}
                  </div>
                )}
              </>
            )}
          </div>
          {/* <ReportSidebar /> */}
        </div>
      </div>
    </>
  );
}

export default TeamMembersList;
