import React from "react";
import { CustomTextInput, PrimaryCta, CustomText } from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useState } from "react";
import Core from "../../common/clientSdk";
import validator from "validator";
import { Navigate, useNavigate } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { useSnackbar } from "react-simple-snackbar";
import { ToastContainer, toast } from "react-toastify";

function InviteMember() {
  const [loading, set_loading] = useState(false);
  const [memberName, setMemberName] = useState("");
  const [email, setEmail] = useState("");
  const [openSnackbar] = useSnackbar();

  const [error, set_error] = useState({
    member_name: "",

    email: "",
  });

  var navigate = useNavigate();

  function updateProgress(status) {
    set_loading(status);
  }
  function validateName(value) {
    if (validator.isEmpty(value, {})) {
      set_error({ ...error, member_name: "Please enter name" });
    } else {
      set_error({ ...error, member_name: "" });
      return true;
    }
  }
  function validateEmail(value) {
    if (!validator.isEmail(value, {})) {
      set_error({ ...error, email: "Please enter valid email" });
    } else {
      set_error({ ...error, email: "" });
      return true;
    }
  }
  function postInviteMembers(progress_cb) {
    var params = {
      name: memberName,
      email: email,
    };

    if (validateName(memberName) && validateEmail(email)) {
      Core.postInviteMembers(
        postInviteMembersSuccess,
        postInviteMembersFailed,
        progress_cb,
        params
      );
    }
  }

  function postInviteMembersSuccess(response_data) {
    toast.success("Successfully  send");
    navigate("/team-members-list");
  }

  function postInviteMembersFailed(errorList) {
    toast.error(errorList);
  }

  return (
    <div className="dashboard-container">
      <SideMenu />

      <div className="main-screen">
        <Header />
        <div className="page-container">
          <div className="campaign-text-heading">
            <CustomText
              p
              text={"Invite Member"}
              textColor={"text_color_primary"}
              fontSize={"font_size_2xl"}
            />
          </div>

          <div className="setting-screen-main-container p-l-15 m-l-20">
            <div className="m-t-15 full-width">
              <CustomTextInput
                type="name"
                placeholder="Name"
                value={memberName}
                onChange={(e) => setMemberName(e.target.value)}
                onBlur={(e) => validateName(e.target.value)}
              />
              <h5 className="error">{error.member_name}</h5>
            </div>
            <div className="m-t-15 full-width">
              <CustomTextInput
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={(e) => validateEmail(e.target.value)}
              />
              <h5 className="error">{error.email}</h5>
            </div>
            <div className="m-t-15 full-width">
              <PrimaryCta
                fontSize={"font_size_large"}
                fullWidth={true}
                text={"Send Invite"}
                isLoading={loading}
                onClick={() => {
                  postInviteMembers(updateProgress);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InviteMember;
