"use-strict";

import * as Actions from "../actions";

const BrandUserData = (state = null, action) => {
  if (action.type === Actions.UPDATE_BRAND_USER_DATA) {
    state = {
      ...state,
      ...action.payload,
    };
  }

  if (action.type === Actions.UPDATE_BRAND_DATA) {
    state = {
      ...state,
      ...action.payload,
    };
  }

  if (action.type === Actions.UPDATE_ORG_DATA) {
    state = {
      ...state,
      ...action.payload,
    };
  }

  return state;
};

export default BrandUserData;
