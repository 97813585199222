// Info: User Module for Core

import Utils from '../../utils';

export const User = { // Public functions accessible by other modules

  getUserId: function(user_data){
    return _User.getUserData(user_data)['user_id']
  },

  getUserName: function(user_data){
    return _User.getUserData(user_data)['name']
  },

  getUserOrgId: function(user_data){
    return _User.getUserData(user_data)['org_id']
  },

  getUserEmail: function(user_data){
    return _User.getUserData(user_data)['email']
  },

  getUserPhoneNumber: function(user_data){
    return _User.getUserData(user_data)['phone_number']
  },


}////////////////////////////Public Functions END///////////////////////////////

const _User ={

  getUserData: function(user_data){
    if(!Utils.isNullOrUndefined(user_data)){
      return user_data
    }
  }
}
