import React from "react";
import { useState } from "react";
import {
  CustomBox,
  CustomText,
  CustomTextInput,
  InviteTeammate,
  PrimaryCta,
  TeamMembers,
} from "../components";
import CustomTable from "../components/customTable";
import Header from "./header";
import SideMenu from "./sideMenu";

const Team = function () {
  //popup invite Member

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  //Requested Member data start
  function renderRequestedTableSchema() {
    return [
      {
        name: "MEMBER",
        cell: (data) => <span>{data.name}</span>,
        //right: true,
        center: true,
        //width: '200px'
        flex: 1,
      },
      {
        name: "EMAIL",
        cell: (data) => <span> {data.email}</span>,
        //left: true,
        // width: '200px'
        center: true,
        flex: 1,
      },
      {
        name: "MOBILE",
        cell: (data) => <span> {data.mobile}</span>,
        //left: true,
        //width: '200px'
        center: true,
        flex: 1,
      },
      {
        name: "MODERATE",
        cell: (data) => <span> {data.moderate}</span>,
        //right: true,
        //width: '200px'
        center: true,
        flex: 1,
      },
    ];
  }

  function renderRequestedBox() {
    return (
      <>
        <div className="m-t-16">
          <CustomText
            label
            text={"Requested"}
            fontSize={"font_size_xl"}
            fontWeight={"rubik_semiBold"}
            textColor={"text_color_primary"}
            textAlign={"text_align_left"}
          />
        </div>
        <div>
          <div style={{ marginTop: 16, flex: 1 }}>
            <CustomTable
              tableSchema={renderRequestedTableSchema()}
              tableData={[
                {
                  name: "ravi",
                  email: "r@gmail.com",
                  mobile: "88246536",
                  moderate: "yes",
                },
                {
                  name: "ravi",
                  email: "r@gmail.com",
                  mobile: "88246536",
                  moderate: "yes",
                },
              ]}
              //   onRowClicked={(campaign_deep_data) =>
              //     navigateToCampaignDetails(campaign_deep_data.campaign_data)
              //   }
              headerBgColor="#ded9d9"
              rowBorderType="solid"
              rowBorderColor="#C6C6C6"
              // rowPaddingTop={12}
              //rowPaddingBottom={12}
            />
          </div>
        </div>
      </>
    );
  }

  //requested Member data end

  //Pending Invitation member Data start

  function renderPendingInvitationTableSchema() {
    return [
      {
        name: "EMAIL",
        cell: (data) => <span> {data.email}</span>,
        //left: true,
        // width: '200px'
        center: true,
        flex: 1,
      },
      {
        name: "STATUS",
        cell: (data) => <span> {data.status}</span>,
        //left: true,
        //width: '200px'
        center: true,
        flex: 1,
      },
    ];
  }

  function renderPendingInvitationBox() {
    return (
      <>
        <div className="m-t-24">
          <CustomText
            label
            text={"Pending Invitation"}
            fontSize={"font_size_xl"}
            fontWeight={"rubik_semiBold"}
            textColor={"text_color_primary"}
            textAlign={"text_align_left"}
          />
        </div>
        <div>
          <div style={{ marginTop: 16, flex: 1 }}>
            <CustomTable
              tableSchema={renderPendingInvitationTableSchema()}
              tableData={[
                {
                  email: "r@gmail.com",
                  status: "yes",
                },
                {
                  email: "r@gmail.com",
                  status: "yes",
                },
              ]}
              //   onRowClicked={(campaign_deep_data) =>
              //     navigateToCampaignDetails(campaign_deep_data.campaign_data)
              //   }
              headerBgColor="#ded9d9"
              rowBorderType="solid"
              rowBorderColor="#C6C6C6"
              // rowPaddingTop={12}
              //rowPaddingBottom={12}
            />
          </div>
        </div>
      </>
    );
  }

  // pending Invitation member Data end

  //Members Data start

  function renderMembersTableSchema() {
    return [
      {
        name: "MEMBERS",
        cell: (data) => <span> {data.members}</span>,
        //left: true,
        // width: '200px'
        center: true,
        flex: 1,
      },
      {
        name: "EMAIL",
        cell: (data) => <span> {data.email}</span>,
        //left: true,
        //width: '200px'
        center: true,
        flex: 1,
      },
      {
        name: "MOBILE",
        cell: (data) => <span> {data.mobile}</span>,
        //left: true,
        // width: '200px'
        center: true,
        flex: 1,
      },
      {
        name: "STATUS",
        cell: (data) => <span> {data.status}</span>,
        //left: true,
        //width: '200px'
        center: true,
        flex: 1,
      },
    ];
  }

  function renderMembersBox() {
    return (
      <>
        <div className="m-t-24">
          <CustomText
            label
            text={"Members"}
            fontSize={"font_size_xl"}
            fontWeight={"rubik_semiBold"}
            textColor={"text_color_primary"}
            textAlign={"text_align_left"}
          />
        </div>
        <div>
          <div style={{ marginTop: 16, flex: 1 }}>
            <CustomTable
              tableSchema={renderMembersTableSchema()}
              tableData={[
                {
                  members: "ravi",
                  email: "r@gmail.com",
                  mobile: "88246536",
                  status: "yes",
                },
                {
                  members: "ravi",
                  email: "r@gmail.com",
                  mobile: "88246536",
                  status: "yes",
                },
              ]}
              //   onRowClicked={(campaign_deep_data) =>
              //     navigateToCampaignDetails(campaign_deep_data.campaign_data)
              //   }
              headerBgColor="#ded9d9"
              rowBorderType="solid"
              rowBorderColor="#C6C6C6"
              textAlign="left"
              // rowPaddingTop={12}
              //rowPaddingBottom={12}
            />
          </div>
        </div>
      </>
    );
  }
  // Members Data end

  /****************************** Email Start ***************************/
  function RenderEmail(props) {
    return (
      <div>
        <div className="m-t-16">
          {/* <label style={{ color: "black" }}>Email</label> */}
          <CustomText
            label
            text={"Email"}
            fontSize={"font_size_large"}
            textAlign={"text_align_left"}
            textColor={"text_color_primary"}
            fontWeight={"rubik_semiBold"}
          />
        </div>

        <div className="m-t-16">
          <CustomTextInput placeholder="Invite multiple team mates using comma seperation" />
        </div>
      </div>
    );
  }

  /****************************** Email Start ***************************/

  function renderHeaderTeam() {
    return (
      <div
        className="m-t-22"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginBottom: "22px",
          background: "white",
        }}
      >
        <div>
          <CustomText
            p
            text="Team"
            fontSize={"font_size_2xl"}
            textAlign={"text_align_left"}
            textColor={"text_color_primary"}
            fontWeight={"rubik_semiBold"}
          />
        </div>
        <div>
          <PrimaryCta text={"+ Invite Member"} onClick={togglePopup} />
          {isOpen && (
            <InviteTeammate
              content={
                <>
                  <div>
                    <CustomText
                      p
                      text="Invite Teammate"
                      fontSize={"font_size_xl"}
                      textAlign={"text_align_left"}
                      textColor={"text_color_primary"}
                      fontWeight={"rubik_semiBold"}
                    />
                  </div>
                  <div className="m-t-16">
                    <CustomText
                      p
                      text="Invite Team members to help you manage creaters Email"
                      fontSize={"font_size_label"}
                      textAlign={"text_align_left"}
                      textColor={"text_color_primary"}
                      fontWeight={"rubik_normal"}
                    />
                  </div>
                  {RenderEmail()}
                  <div
                    className="m-t-16"
                    style={{ display: "flex", justifyContent: "right" }}
                  >
                    <PrimaryCta text="cancel" />
                    <span style={{ paddingLeft: "20px" }}></span>
                    <PrimaryCta text="Invite" />
                  </div>
                </>
              }
              handleClose={togglePopup}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div className="page-container">
          <div className="team-member-header">
            <CustomText
              p
              text={"Team Members"}
              textColor={"text_color_primary"}
              fontSize={"font_size_2xl"}
            />
            <PrimaryCta
              third
              fontSize={"font_size_regular"}
              className="create-campaign"
              text={"Invite Member"}
              onClick={() => ""}
            />
          </div>
          <div className="content-row">
            <TeamMembers
              source={require("../resources/images/bg.jpeg")}
              Name={"Sandeep Kumar"}
              title={"Campaign Manger"}
              Email={"Sk98282@gmail.com"}
              state={"ACTIVE"}
              iconName={"instagramIcon"}
            />
          </div>
          <div></div>
        </div>
        {/* <CustomBox
          display="flex"
          flexDirection="column"
          paddingTop="20px"
          paddingRight="20px"
          paddingBottom="20px"
          paddingLeft="20px"
        >
          <div>{renderHeaderTeam()}</div>
          <div>
            {renderRequestedBox()}
            {renderPendingInvitationBox()}
            {renderMembersBox()}
          </div>
        </CustomBox> */}
      </div>
    </div>
  );
};

export default Team;
