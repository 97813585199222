import React, { useEffect, useState } from "react";
import { CustomText, Image, PrimaryCta } from "../../components";
import Utils from "../../common/utils";
import Icon from "../../components/core_component/icon";
import { Loader } from "../loader";
import Core from "../../common/clientSdk";
import { useSnackbar } from "react-simple-snackbar";
import { useSelector } from "react-redux";

function PayoutFlyOut({
  payoutOpen,
  toggleFlyoutDropdown,
  contentsDeepData,
  isLoadingButton,
}) {
  const [isLoading, setLoading] = useState(false);
  const [createOrder, setCreateOrder] = useState();
  const [pgOrderId, setPgOrderId] = useState("");
  const [orgData, setOrgData] = useState({});
  const [address, setAddress] = useState("");
  const [openSnackbar] = useSnackbar();
  const phonePayGateWay = process.env.REACT_APP_API_PHONE_PAY_URL;
  useEffect(() => {
    postCreateOrderAssignment();
    getOrgDataUser();
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  var redux_selector = useSelector((state) => {
    return {
      Auth: state.BrandUserAuthReducer,
      UserData: state.BrandUserDataReducer,
    };
  });

  const { user_data, brand_deep_data } = redux_selector.UserData;

  function getOrgDataUser() {
    const params = {
      org_id: brand_deep_data.brand_data.org_id,
    };
    Core.getOrgDataUser(
      getOrgDataUserSuccess,
      getOrgDataUserFailed,
      () => {},
      params
    );
  }

  function getOrgDataUserSuccess(response_data) {
    setOrgData(response_data.org_data.org_data);
    setAddress(response_data.org_data.address_data.address_pincode);
  }

  function getOrgDataUserFailed(errorList) {
    openSnackbar(errorList);
  }

  function postCreateOrderAssignment() {
    const params = {
      assignment_id: contentsDeepData?.assignment_data.assignment_id,
      brand_id: contentsDeepData?.assignment_data.brand_id,
      redirect_url:
        window.location.origin +
        "/contracts/" +
        contentsDeepData?.assignment_data.assignment_id,

      platform: "web",
    };
    Core.postCreateOrderAssignment(
      postCreateOrderAssignmentSuccess,
      postCreateOrderAssignmentFailed,
      updateProgress,
      params
    );
  }

  function postCreateOrderAssignmentSuccess(response_data) {
    setCreateOrder(response_data.assignment_order_data);
    const pgOrderId = response_data.assignment_order_data.pg_order_id;
    setPgOrderId(pgOrderId);

    // redirectToPaymentPage(pgOrderId);
  }

  function postCreateOrderAssignmentFailed(errorList) {
    openSnackbar(errorList);
  }

  const { is_kyc_done, is_verified, gst_number, pan_number } = orgData;

  function redirectToPaymentPage() {
    const paymentUrl = `${phonePayGateWay}${pgOrderId}`;
    window.location.href = paymentUrl;
    setLoading(true);
  }

  const assignment_id = contentsDeepData?.assignment_data?.assignment_id;
  const { reward_data } = contentsDeepData;

  return (
    <div className={`sidebar ${payoutOpen ? "open" : ""}`}>
      <div className="sidebar-content">
        {isLoading && Utils.isUndefined(createOrder?.amount) ? (
          <Loader />
        ) : (
          <>
            <div style={{ maxHeight: "80%", overflowY: "auto" }}>
              <div className="payment-summary-heading">
                <CustomText h5 text={"Payment Summary"} className="h6Text" />
                {/* <button onClick={toggleFlyoutDropdown}>Close</button> */}
                <div className="cursor-pointer" onClick={toggleFlyoutDropdown}>
                  {" "}
                  <Image
                    source={require("../../resources/images/bid-search-cross.png")}
                  />
                </div>
              </div>
              <div className="flyout-summary-campaign-detail">
                <div className="summary-campaign-detail">
                  <CustomText
                    p
                    text={"Campaign Name"}
                    fontSize={"font_size_large"}
                    textColor={"text_color_primary"}
                    fontWeight={"rubik_regular"}
                    height={"line_height_19_2"}
                    className="w-150"
                  />
                  <CustomText
                    p
                    text={contentsDeepData?.campaign_data?.name}
                    fontSize={"font_size_large"}
                    textColor={"text_color_primary"}
                    fontWeight={"Inter_medium_500"}
                    height={"line_height_19_2"}
                    textAlign={"text_align_left"}
                  />
                </div>
                <div className="summary-contract-detail">
                  <CustomText
                    p
                    text={"Contract ID"}
                    fontSize={"font_size_large"}
                    textColor={"text_color_primary"}
                    fontWeight={"rubik_regular"}
                    height={"line_height_19_2"}
                    className="w-150"
                  />
                  <CustomText
                    p
                    text={`#${contentsDeepData?.assignment_data?.assignment_id}`}
                    fontSize={"font_size_large"}
                    textColor={"text_color_primary"}
                    fontWeight={"Inter_medium_500"}
                    height={"line_height_19_2"}
                  />
                </div>
              </div>
              <div className="content-payout">
                {contentsDeepData.contents_deep_data &&
                  Object.keys(contentsDeepData.contents_deep_data).map(
                    (item, index) => {
                      const { name, platform_id } =
                        contentsDeepData.contents_deep_data[item].platform_data;
                      const { content_id, status, media_id } =
                        contentsDeepData.contents_deep_data[item].content_data;

                      if (status == "IN_REVIEW") {
                        if (media_id?.split(".").pop() == "mp4") {
                          return (
                            <>
                              {index === 0 && (
                                <div
                                  className="content-pending-card"
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}>
                                    <div className="m-r-8">
                                      <Icon
                                        iconName={Utils.socialMediaIcons(
                                          platform_id
                                        )}
                                        height={24}
                                        width={24}
                                      />
                                    </div>

                                    <CustomText
                                      p
                                      className="content-pending-card-text"
                                      text={name}
                                      textColor={"text_color_gray_500"}
                                    />
                                  </div>
                                </div>
                              )}

                              <div className="content-pending-content-imagecard">
                                <div className="content-status-media">
                                  <div>
                                    <video
                                      preload="metadata"
                                      width={80}
                                      height={80}
                                      type={"video/mp4"}>
                                      <source
                                        src={Utils.constructContentMediaUrl(
                                          assignment_id,
                                          media_id
                                        )}
                                        type="video/mp4"></source>
                                    </video>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        } else {
                          return (
                            <div className="flyout-content-detail-container">
                              <div className="flyout-content-detail">
                                <div className="content-pending-content-imagecard">
                                  <div>
                                    <Image
                                      source={Utils.constructContentMediaUrl(
                                        assignment_id,
                                        media_id
                                      )}
                                      width={80}
                                      height={80}
                                    />
                                  </div>
                                </div>
                                {index === 0 && (
                                  <div
                                    style={{
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}>
                                      <div className="m-r-8">
                                        <Icon
                                          iconName={Utils.socialMediaIcons(
                                            platform_id
                                          )}
                                          height={24}
                                          width={24}
                                        />
                                      </div>

                                      <CustomText
                                        p
                                        className="content-pending-card-text"
                                        text={name}
                                        textColor={"text_color_gray_500"}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div>
                                <CustomText
                                  p
                                  text={reward_data?.value?.amount}
                                />
                              </div>
                            </div>
                          );
                        }
                      }
                    }
                  )}
              </div>
              <div className="flyout-summary-campaign-detail">
                <div className="summary-campaign-detail">
                  <CustomText
                    p
                    text={"Amount"}
                    fontSize={"font_size_large"}
                    textColor={"text_color_primary"}
                    fontWeight={"rubik_regular"}
                    height={"line_height_19_2"}
                    className="w-150"
                  />
                  <CustomText
                    p
                    text={"₹" + reward_data?.value?.amount}
                    fontSize={"font_size_large"}
                    textColor={"text_color_primary"}
                    fontWeight={"Inter_medium_500"}
                    height={"line_height_19_2"}
                  />
                </div>
                <div className="summary-campaign-detail">
                  <CustomText
                    p
                    text={"GST"}
                    fontSize={"font_size_large"}
                    textColor={"text_color_primary"}
                    fontWeight={"rubik_regular"}
                    height={"line_height_19_2"}
                    className="w-150"
                  />
                  <CustomText
                    p
                    text={"₹" + reward_data?.value?.amount * (18 / 100)}
                    fontSize={"font_size_large"}
                    textColor={"text_color_primary"}
                    fontWeight={"Inter_medium_500"}
                    height={"line_height_19_2"}
                  />
                </div>
                <div className="summary-contract-detail">
                  <CustomText
                    p
                    text={"Total Amount"}
                    fontSize={"font_size_large"}
                    textColor={"text_color_primary"}
                    fontWeight={"rubik_regular"}
                    height={"line_height_19_2"}
                    className="w-150"
                  />
                  <CustomText
                    p
                    text={"₹" + createOrder?.amount}
                    fontSize={"font_size_large"}
                    textColor={"text_color_primary"}
                    fontWeight={"Inter_medium_500"}
                    height={"line_height_19_2"}
                  />
                </div>
              </div>
            </div>
            <div className="contract-pay-button">
              <PrimaryCta
                fontSize={"font_size_large"}
                fullWidth={true}
                text={`Pay ₹${createOrder?.amount}`}
                onClick={() => redirectToPaymentPage()}
                isLoading={isLoading}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default PayoutFlyOut;
