import React from "react";
import { CustomTextInput, PrimaryCta, CustomText } from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useState, useEffect } from "react";
import Core from "../../common/clientSdk";
import validator from "validator";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { Loader } from "../loader";
import { useSnackbar } from "react-simple-snackbar";
import { flexbox } from "@mui/system";
import Select from "react-dropdown-select";
import Style from "../../resources/style";
import * as CampaignData from "../../common/data/campaign";
import Multiselect from "multiselect-react-dropdown";
import Utils from "../../common/utils";

function CompanyAddressEdit() {
  const [loading, setLoading] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [pan, setPan] = useState("");
  const [gst, setGst] = useState("");
  const [premise, setPremise] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");

  const [country, setCountry] = useState("India");
  const [pinCode, setPinCode] = useState("");
  const [addressData, setAddressData] = useState({});
  const [orgData, setOrgData] = useState({});
  const [state, setState] = useState("");

  const [error, set_error] = useState({
    premise: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pinCode: "",
  });
  useEffect(() => {
    getOrgDetails(updateProgress);
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    setGst(orgData?.gst_number || "");
    setPan(orgData?.pan_number || "");
    setPremise(addressData?.address_premise || "");
    setStreet(addressData?.address_street || "");
    setCity(addressData?.address_city || "");
    setState(addressData?.address_state || "");

    setPinCode(addressData?.address_pincode || "");
  }, [addressData]);

  function updateProgress(status) {
    setLoading(status);
  }
  function validatePAN(value = "") {
    if (validator.isEmpty(value, {})) {
      openSnackbar("Please enter pan number");
      return false;
    } else if (!validator.isLength(value, { min: 10, max: 10 })) {
      openSnackbar("Please enter a valid PAN number");
    } else {
      return true;
    }
  }
  function validateGST(value = "") {
    if (validator.isEmpty(value, {})) {
      return true;
    } else if (!validator.isLength(value, { min: 15, max: 15 })) {
      openSnackbar("Please enter a valid GST number");
    } else {
      return true;
    }
  }

  function validatePremise(value = "") {
    if (!value && validator.isEmpty(value, {})) {
      openSnackbar("Please enter premise");
      return false;
    } else {
      return true;
    }
  }
  function validateStreet(value = "") {
    if (validator.isEmpty(value, {})) {
      openSnackbar("Please enter street");
      return false;
    } else {
      return true;
    }
  }
  function validateCity(value = "") {
    if (validator.isEmpty(value, {})) {
      openSnackbar("Please enter city name");
      return false;
    } else {
      return true;
    }
  }

  function validateState(value) {
    if (state.length == 0) {
      openSnackbar("Please select state");
      return false;
    }
    // if (validator.isEmpty(value, [])) {
    //   openSnackbar("Please select state");
    //   return false;
    // } else {
    //   return true;
    // }
    return true;
  }

  function validateCountry(value = "") {
    if (validator.isEmpty(value, {})) {
      openSnackbar("Please enter country");
      return false;
    } else {
      set_error({ ...error, country: "" });
      return true;
    }
  }

  function validatePinCode(value = "") {
    if (validator.isEmpty(value, {})) {
      openSnackbar("Please enter PinCode");
      return false;
    } else {
      set_error({ ...error, pinCode: "" });
      return true;
    }
  }

  function validateGSTIN() {
    if (!pan) {
      openSnackbar("Please enter PAN number");
      return false;
    }
    if (pan.length !== 10 || !Utils.validatePan(pan)) {
      openSnackbar("Please enter a valid PAN");
      return false;
    }

    if (gst.length > 0 && gst.length !== 15) {
      openSnackbar("Please enter a valid GST Number");
      return false;
    }

    if (gst.length > 0 && !Utils.validateGst(gst)) {
      openSnackbar("Please enter a valid GSTIN");
      return false;
    }
    return true;
  }

  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;
  const { user_data = {} } = BrandUser;

  const { brand_deep_data = {} } = BrandUser;
  const { brand_data = {} } = brand_deep_data;

  function getOrgDetails(progress_cb) {
    const params = {
      org_id: brand_data.org_id,
    };
    Core.getOrgUserDetails(
      getOrgDetailsSuccess,
      getOrgDetailsFailed,
      progress_cb,
      params
    );
  }

  function getOrgDetailsSuccess(response_data) {
    setAddressData(response_data.org_data.address_data);
    setOrgData(response_data.org_data.org_data);
    setLoading(false);
  }

  function getOrgDetailsFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function postUpdateUserOrg(progress_cb) {
    const params = {
      org_id: brand_data.org_id,
      pan_number: pan,
      gst_number: gst,
      address_data: JSON.stringify({
        address_premise: premise,
        address_street: street,
        address_city: city,
        address_state: state,
        address_country: country,
        address_pincode: pinCode,
      }),
    };
    if (
      validatePremise(premise) &&
      validateStreet(street) &&
      validatePAN(pan) &&
      validateCity(city) &&
      validateState(state) &&
      validateCountry(country) &&
      validatePinCode(pinCode) &&
      validateGSTIN()
    ) {
      Core.postUpdateUserOrg(
        postUpdateUserOrgSuccess,
        postUpdateUserOrgFailed,
        progress_cb,
        params
      );
    }
  }

  function postUpdateUserOrgSuccess(response_data) {
    const successMessage = "Address Add successful!";
    showError(successMessage);
    setLoading(false);
    navigate("/company-settings");
  }

  function postUpdateUserOrgFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function showError(message) {
    openSnackbar(message);
  }

  function updateAddress(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setState(value);
  }
  return (
    <div className="dashboard-container">
      <SideMenu />

      <div className="main-screen">
        <Header />
        <div className="page-container">
          <div className="campaign-text-heading">
            <CustomText
              p
              text={"Tax and Billing"}
              textColor={"text_color_primary"}
              fontSize={"font_size_2xl"}
            />
          </div>

          <div className="setting-screen-main-container">
            <div className="m-b-12 p-t-10"></div>
            {loading ? (
              <Loader />
            ) : (
              <>
                <CustomText
                  p
                  text={"Tax details"}
                  textColor={"text_color_primary"}
                  fontSize={"font_size_xl"}
                />

                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginBottom: 20,
                  }}
                >
                  <div className="m-t-15" style={{ marginRight: 10 }}>
                    <CustomTextInput
                      type="name"
                      placeholder="PAN Number"
                      value={pan}
                      onChange={(e) => setPan(e.target.value)}
                    />
                    <h5 className="error">{error.premise}</h5>
                  </div>
                  <div className="m-t-15 ">
                    <CustomTextInput
                      type="name"
                      placeholder="GST Number"
                      value={gst}
                      onChange={(e) => setGst(e.target.value)}
                    />
                    <h5 className="error">{error.street}</h5>
                  </div>
                </div>
                <CustomText
                  p
                  text={"Billing Address"}
                  textColor={"text_color_primary"}
                  fontSize={"font_size_xl"}
                />
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  <div className="m-t-15" style={{ marginRight: 10 }}>
                    <CustomTextInput
                      type="name"
                      placeholder="Premise"
                      value={premise}
                      onChange={(e) => setPremise(e.target.value)}
                    />
                    <h5 className="error">{error.premise}</h5>
                  </div>
                  <div className="m-t-15 ">
                    <CustomTextInput
                      type="name"
                      placeholder="Street"
                      value={street}
                      onChange={(e) => setStreet(e.target.value)}
                    />
                    <h5 className="error">{error.street}</h5>
                  </div>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  <div className="m-t-15" style={{ marginRight: 10 }}>
                    <CustomTextInput
                      type="name"
                      placeholder="City"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                    <h5 className="error">{error.city}</h5>
                  </div>
                  <div className="m-t-15 full-width">
                    <Multiselect
                      selectedValues={Object.values(
                        Utils.getSelectedLocationObjects([
                          addressData?.address_state,
                        ])
                      )}
                      placeholder="Select State"
                      id="css_custom"
                      options={Object.values(CampaignData.LOCATIONS)}
                      onSelect={(selectedList, selectedItem) =>
                        updateAddress(selectedList, selectedItem)
                      }
                      onRemove={(selectedList, selectedItem) =>
                        updateAddress(selectedList, selectedItem)
                      }
                      singleSelect={true}
                      displayValue={"n"}
                      showArrow={false}
                      style={{
                        chips: Style.chipsAddress,
                        option: Style.option,
                        optionContainer: Style.optionContainer,
                        multiselectContainer: Style.multiselectContainerAddress,
                        searchBox: Style.searchBoxAddress,
                        inputField: Style.inputFieldAddress,
                      }}
                    />
                    {/* <Select
                      multi={false}
                      options={stateList}
                      values={state.map((value) => ({ value }))}
                      onChange={(value) => setState(value)}
                      placeholder="Select Followers"
                      autoFocus={false}
                      color="#c8c2c2"
                      // Customcolor
                      style={{
                        border: "#fff",
                        backgroundColor: "rgb(29, 22, 45)",
                        paddingBottom: "15px",
                        paddingTop: "15px",
                        marginRight: "10px",
                        border: "1px solid rgb(74, 74, 74)",
                        borderRadius: "5px",
                        width: "369px",
                        option: {
                          color: "#fff",
                        },
                      }}
                    /> */}
                    {/* <CustomTextInput
                      type="name"
                      placeholder="State"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    /> */}
                    <h5 className="error">{error.state}</h5>
                  </div>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  <div className="m-t-15" style={{ marginRight: 10 }}>
                    <CustomTextInput
                      type="name"
                      placeholder="Country"
                      disabled
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                    <h5 className="error">{error.country}</h5>
                  </div>
                  <div className="m-t-15 full-width">
                    <CustomTextInput
                      type="name"
                      placeholder="Pincode"
                      value={pinCode}
                      onChange={(e) => setPinCode(e.target.value)}
                    />
                    <h5 className="error">{error.pinCode}</h5>
                  </div>
                </div>
                <div
                  className="m-t-15 full-width"
                  style={{ justifyContent: "center" }}
                >
                  <PrimaryCta
                    fontSize={"font_size_large"}
                    fifth
                    text={"Save"}
                    isLoading={loading}
                    onClick={() => {
                      postUpdateUserOrg(updateProgress);
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyAddressEdit;
