// Info: Campaign Module for Core

import Utils from "../utils";

const Campaign = {
  // Public functions accessible by other modules

  createCampaignData: function (brand_id) {
    return {
      //user_id: user_id,
      brand_id: brand_id,
    };
  },

  updateCampaignName: function (campaign_deep_data, name) {
    if (Utils.isNullOrUndefined(name)) {
      return campaign_deep_data["campaign_data"];
    }

    campaign_deep_data["campaign_data"]["name"] = name;

    return campaign_deep_data;
  },

  updateCampaignType: function (campaign_deep_data, type) {
    if (Utils.isNullOrUndefined(type)) {
      return campaign_deep_data["campaign_data"];
    }

    campaign_deep_data["campaign_data"]["type"] = type;

    return campaign_deep_data;
  },

  updateMaxBudgetCampaignBudget: function (campaign_deep_data, budget) {
    if (Utils.isNullOrUndefined(budget)) {
      return campaign_deep_data["campaign_data"];
    }

    campaign_deep_data["campaign_data"]["max_budget"] = budget;

    return campaign_deep_data;
  },

  updateCampaignEndDate: function (campaign_deep_data, end_date) {
    if (Utils.isNullOrUndefined(end_date)) {
      return campaign_deep_data["campaign_data"];
    }

    campaign_deep_data["campaign_data"]["end_date"] = end_date;

    return campaign_deep_data;
  },

  updateCampaignDeliveryDate: function (campaign_deep_data, delivery_date) {
    if (Utils.isNullOrUndefined(delivery_date)) {
      return campaign_deep_data["campaign_data"];
    }

    campaign_deep_data["campaign_data"]["delivery_date"] = delivery_date;

    return campaign_deep_data;
  },

  createPlatformData: function (platform_data) {
    // if(Utils.isNullOrUndefined(platform_name)){
    //   return campaign_deep_data
    // }

    return {
      platform_data: {
        platform_id: platform_data.platform_id,
      },
    };
  },

  createContentData: function (content_data) {
    // if(Utils.isNullOrUndefined(platform_name)){
    //   return campaign_deep_data
    // }

    return {
      content_data: {
        content_id: content_data.content_id,
      },
    };
  },

  convertPlatformDataIntoArray: function (platforms_data) {
    if (!Utils.isEmptyObject(platforms_data)) {
      return Object.values(platforms_data).map((platform_data) => {
        platform_data["content_types"] = Object.values(
          platform_data.content_types
        );
        return platform_data;
      });
    }
  },

  updateAudienceAgeRange: function (data, age_list) {
    if (Utils.isEmpty(data) && Utils.isEmpty(age_list)) {
      return data;
    }

    data["audience_data"] = {
      ...data["audience_data"],
      age: age_list,
    };

    return data;
  },

  updateInfluencerAgeRange: function (data, age_list) {
    if (Utils.isEmpty(data) && Utils.isEmpty(age_list)) {
      return data;
    }

    data["influencer_data"] = {
      ...data["influencer_data"],
      age: age_list,
    };

    return data;
  },

  updateAudienceGender: function (data, gender) {
    if (Utils.isEmpty(data) && Utils.isEmpty(gender)) {
      return data;
    }

    data["audience_data"] = {
      ...data["audience_data"],
      gender: gender,
    };

    return data;
  },

  updateInfluencerGender: function (data, gender) {
    if (Utils.isEmpty(data) && Utils.isEmpty(gender)) {
      return data;
    }

    data["influencer_data"] = {
      ...data["influencer_data"],
      gender: gender,
    };

    return data;
  },

  updateAudienceLocation: function (data, state) {
    if (Utils.isEmpty(data) && Utils.isEmpty(state)) {
      return data;
    }

    data["audience_data"] = {
      ...data["audience_data"],
      state: state,
    };

    return data;
  },

  updateInfluencerLocation: function (data, state) {
    if (Utils.isEmpty(data) && Utils.isEmpty(state)) {
      return data;
    }

    data["influencer_data"] = {
      ...data["influencer_data"],
      state: state,
    };

    return data;
  },

  updateAudienceLanguage: function (data, language) {
    if (Utils.isEmpty(data) && Utils.isEmpty(language)) {
      return data;
    }
    data["audience_data"] = {
      ...data["audience_data"],
      language: language,
    };

    return data;
  },

  updateInfluencerLanguage: function (data, language) {
    if (Utils.isEmpty(data) && Utils.isEmpty(language)) {
      return data;
    }

    data["influencer_data"] = {
      ...data["influencer_data"],
      language: language,
    };

    return data;
  },

  updateInfluencerMaxFollowers: function (data, maximum_followers) {
    if (Utils.isEmpty(data) && Utils.isEmpty(maximum_followers)) {
      return data;
    }
    data["influencer_data"] = {
      ...data["influencer_data"],
      maximum_followers: maximum_followers,
    };

    return data;
  },

  updateInfluencerMinFollowers: function (data, minimum_followers) {
    if (Utils.isEmpty(data) && Utils.isEmpty(minimum_followers)) {
      return data;
    }
    data["influencer_data"] = {
      ...data["influencer_data"],
      minimum_followers: minimum_followers,
    };
    return data;
  },

  updateInfluencerCatagories: function (data, category) {
    if (Utils.isEmpty(data) && Utils.isEmpty(category)) {
      return data;
    }
    data["influencer_data"] = {
      ...data["influencer_data"],
      category: category,
    };

    return data;
  },

  updateAudienceInterest: function (data, interests) {
    if (Utils.isEmpty(data) && Utils.isEmpty(interests)) {
      return data;
    }
    data["audience_data"] = {
      ...data["audience_data"],
      interests: interests,
    };

    return data;
  },

  updateRewardTypeData: function (data, reward_type) {
    if (Utils.isNullOrUndefined(reward_type)) {
      return data["reward_data"];
    }

    data["reward_data"] = {
      ...data["reward_data"],
      type: reward_type,
    };

    return data;
  },

  createRewardData: function () {
    return {
      reward_by_follower: {
        below_5k: "",
        above_5k_below_10k: "",
        above_10k_below_25k: "",
        above_25k_below_1m: "",
      },
    };
  },

  updateRewardByFollowersData: function (data, key, value, reward_by_follower) {
    data["reward_data"]["reward_by_follower"] = {
      ...data["reward_data"]["reward_by_follower"],
      [key]: value,
    };

    return data;
  },

  updateInstructionNoteData: function (data, instruction_note) {
    if (Utils.isEmpty(data) && Utils.isEmpty(instruction_note)) {
      return data;
    }

    data["instruction_data"] = {
      ...data["instruction_data"],
      instruction_note: instruction_note,
    };

    return data;
  },

  updateDoData: function (data, item) {
    if (Utils.isEmpty(data) && Utils.isEmpty(item)) {
      return data;
    }
    data["instruction_data"] = {
      ...data["instruction_data"],
      do: item,
    };

    return data;
  },

  updateDoNotData: function (data, do_not) {
    if (Utils.isEmpty(data) && Utils.isEmpty(do_not)) {
      return data;
    }
    data["instruction_data"] = {
      ...data["instruction_data"],
      do_not: do_not,
    };

    return data;
  },

  getCampaignPlatform: function (content_list) {
    let platform_data = {};
    content_list.forEach((item) => {
      if (item.platform_data.platform_id in platform_data) {
        return;
      } else {
        platform_data = {
          ...platform_data,
          [item["platform_data"]["platform_id"]]: item.platform_data,
        };
      }
    });

    return platform_data;
  },

  getPlatformContents: function (platform_id, content_list) {
    return content_list.filter(
      (content_deep_data) =>
        content_deep_data["platform_data"]["platform_id"] == platform_id
    );
  },
}; ////////////////////////////Public Functions END///////////////////////////////

export default Campaign;
