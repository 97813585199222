import React, { useState, useEffect } from "react";
//import {Link} from 'react-router-dom'

// Import Redux State (Private scope)
import { useSelector } from "react-redux";

import {
  CustomText,
  PrimaryCta,
  CampaignList,
  FilterBtn,
  FallBack,
  CampaignListAllData,
  ProposalsList,
  CampaignListDropdown,
} from "../components";
import Core from "../common/clientSdk.js";
import Icon from "../components/core_component/icon";
import MathPlus from "../resources/images/math-plus.svg";
import CustomTable from "../components/customTable";
import SideMenu from "./sideMenu";
import Header from "./header";
import { Search } from "../components/assets/icons";
import { useNavigate } from "react-router-dom";
import { Loader } from "./loader";
import utils from "../common/utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSnackbar } from "react-simple-snackbar";
import ReportSidebar from "./report-sidebar";
import { ToastContainer, toast } from "react-toastify";

const limit = 10;
const campaignStatusList = [
  "ALL",
  "IN_REVIEW",
  "ACTIVE",
  "COMPLETED",
  "INACTIVE",
];

const CampaignListingScreen = function (props) {
  const [campaignList, setCampaignList] = useState([]);
  const [start, setStart] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openSnackbar] = useSnackbar();
  const [next, setNext] = useState(10);
  const [isActive, setIsActive] = useState(false);
  const [creditsDetail, setCreditsDetail] = useState({});
  const [filterStatus, setFilterStatus] = useState("ALL");
  const [orgData, setOrgData] = useState({});
  const [address, setAddress] = useState({});
  const [statusColors] = useState({
    ACTIVE: "#FABC13",
    INACTIVE: "red",
    COMPLETED: "#009A29",
    IN_REVIEW: "#1977f2",
  });

  var navigate = useNavigate();

  useEffect(() => {
    getCampaignListUser(updateProgress);
    getCreditDetail(updateProgress);
    getOrgDataUser(updateProgress);
  }, [filterStatus]);

  function updateProgress(status) {
    setLoading(status);
  }

  function createCampaign() {
    // if (address && is_verified) {
    if (creditsDetail < 10) {
      toast.info("Not enough credits for create campaign");
      return false;
    } else {
      navigate("/create-campaign");
    }
    // } else {
    //   navigate("/company-settings");
    //   toast.info("Profile not completed");
    // }
  }

  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  function getCampaignListUser() {
    var params = {
      brand_id: redux_selector.BrandUser.brand_deep_data.brand_data.brand_id,
    };
    if (filterStatus != "ALL") {
      params.status = filterStatus;
    }

    Core.getCampaignListUser(
      getCampaignListUserSuccess,
      getCampaignListUserFailed,
      updateProgress,
      params
    );
  }

  function getCampaignListUserSuccess(response_data) {
    console.log("res", response_data);
    setCampaignList(response_data["campaign_list"]);
    // if (next == 0) {
    //   // API called first time
    //   setCampaignList(response_data["campaign_list"]);
    // } else {
    //   setCampaignList([...campaignList, ...response_data["campaign_list"]]);
    // }

    // setNext(response_data?.pagination_data?.next);
  }

  function getCampaignListUserFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function getCreditDetail(progress_cb) {
    var params = {};
    Core.getCreditDetail(
      getCreditDetailSuccess,
      getCreditDetailFailed,
      progress_cb,
      params
    );
  }

  function getCreditDetailSuccess(response_data) {
    setCreditsDetail(response_data.credit_data);
  }

  function getCreditDetailFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function getOrgDataUser(progress_cb) {
    const params = {
      org_id: redux_selector.BrandUser.brand_deep_data.brand_data.org_id,
    };
    Core.getOrgDataUser(
      getOrgDataUserSuccess,
      getOrgDataUserFailed,
      progress_cb,
      params
    );
  }

  function getOrgDataUserSuccess(response_data) {
    setOrgData(response_data.org_data.org_data);
    setAddress(response_data.org_data.address_data.address_premise);
  }

  function getOrgDataUserFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function renderTableSchema() {
    return [
      {
        name: "CAMPAIGN NAME",
        cell: (data) => <span>{data.campaign_data.name}</span>,
        //right: true,
        center: true,
        //width: '200px'
        flex: 1,
      },
      {
        name: "STATUS",
        cell: (data) => <span> {data.campaign_data.status}</span>,
        //left: true,
        // width: '200px'
        center: true,
        flex: 1,
      },
      {
        name: "BIDS",
        cell: (data) => <span> {data.name}</span>,
        //left: true,
        //width: '200px'
        center: true,
        flex: 1,
      },
      {
        name: "BIDS ACCEPTED",
        cell: (data) => <span> {data.name}</span>,
        //right: true,
        //width: '200px'
        center: true,
        flex: 1,
      },
      {
        name: "MAX BID AMOUNT",
        cell: (data) => <span> {data.name}</span>,
        //left: true,
        center: true,
        //width: '200px'
        flex: 1,
      },
      {
        name: "BUDGET",
        cell: (data) => <span> {data.campaign_data.max_budget}</span>,
        //right: true,
        center: true,
        //width: '200px'
        flex: 1,
      },
      {
        name: "EXPIRES ON",
        cell: (data) => <span> {data.name}</span>,
        //right: true,
        center: true,
        //width: '200px'
        flex: 1,
      },
      {
        name: "CREATED ON",
        cell: (data) => <span> {data.name}</span>,
        //right: true,
        center: true,
        //width: '200px'
        flex: 1,
      },
    ];
  }

  function CampaignClick(campaignId) {
    navigate(`/campaigns/${campaignId}`);
  }
  const { is_kyc_done, is_verified } = orgData;

  // Custom table data start

  const tableSchema = [
    {
      name: "Campaign Name",
      selector: (row) => row["campaign_data"]["name"],
      // sortable: true,
      cell: (data) => (
        <span
          style={{ textAlign: "left" }}
          onClick={(e) => {
            CampaignClick(data.campaign_data.campaign_id);
            e.stopPropagation();
          }}
        >
          {utils.truncateWithEllipsis(data.campaign_data.name, 60)}
        </span>
      ),
    },
    {
      name: "Views",
      selector: (row) => row["campaign_data"]["seen_count"],
      // sortable: true,
      maxWidth: "121px",
    },
    {
      name: "Proposals",
      selector: (row) => row["campaign_data"]["bid_count"],
      // sortable: true,
      maxWidth: "121px",
      //cell: (data) => <span>{data.campaign_data.bid_count}</span>,
    },
    {
      name: "Contracts",
      selector: (row) => row["campaign_data"]["assignment_count"],
      // sortable: true,
      maxWidth: "121px",
      //cell: (data) => <span>{data.campaign_data.assignment_count}</span>,
    },
    {
      name: "Status",
      selector: (row) =>
        utils.assignmentStatusToString(row["campaign_data"]["status"]),
      // sortable: true,
      maxWidth: "195px",
      cell: (data) => (
        <span
          style={{ color: statusColors[data.campaign_data.status] }}
          onClick={(e) => {
            CampaignClick(data.campaign_data.campaign_id);
            e.stopPropagation();
          }}
        >
          {utils.assignmentStatusToString(data.campaign_data.status)}
        </span>
      ),
    },
    {
      name: "Type",
      selector: (row) => utils.getCampaignType(row["campaign_data"]["type"]),
      // sortable: true,
      maxWidth: "149px",
    },
    // {
    //   name: "Engagement Type",
    //   selector: (row) =>
    //     utils.engagementType(row["campaign_data"]["engagement_type"]),
    //   // sortable: true,
    //   maxWidth: "200px",
    // },
  ];

  const tableData = campaignList.map((campaignItem) => ({
    campaign_id: campaignItem.campaign_data.campaign_id,
    name: campaignItem.campaign_data.name,
    views: campaignItem.campaign_data.seen_count,
    proposals: campaignItem.campaign_data.bid_count,
    contracts: campaignItem.campaign_data.assignment_count,
    status: campaignItem.campaign_data.status,
    type: utils.getCampaignType(campaignItem.campaign_data.type),
    expiringOn: utils.convertUnixToDate(campaignItem.campaign_data.date_added),
  }));

  // Define custom styles for table headers
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "var(--general-secondary-color)", // Change header background color
        color: "var(--gray-gray-300)", // Change header text color
        fontSize: "16px",
        fontWeight: 600,
        lineHight: "19.2px",
        border: "none",
      },
    },
    headCells: {
      style: {
        padding: "10px 0px 10px 20px",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: 400,
        lineHight: "19.2px",
        "&:hover": {
          backgroundColor: "var(--general-secondary-color)",
          fontWeight: 600,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "20px",
      },
    },
  };

  // custom table data end

  return (
    <div className="dashboard-container">
      <SideMenu />

      <div className="main-screen">
        <Header />
        <div className="page-container-campaignList">
          {loading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            <>
              {/* <div className="campaign-text-heading">
                  <CustomText
                    p
                    text={"Campaigns"}
                    textColor={"text_color_primary"}
                    fontSize={"font_size_2xl"}
                  />
                </div> */}
              {/* <div className="campaignlist-header">
                <CampaignListDropdown
                  dropdownItems={campaignStatusList}
                  onItemClick={(item) => {
                    setNext(0);
                    setFilterStatus(item);
                  }}
                />
              </div> */}
              <div className="campaignlist-header">
                <CampaignListDropdown
                  dropdownItems={campaignStatusList}
                  onItemClick={(item) => {
                    setNext(0);
                    setFilterStatus(item);
                  }}
                />
              </div>

              {campaignList.length !== 0 ? (
                <>
                  <div className="custom-table">
                    <CustomTable
                      className="CustomTable"
                      tableSchema={tableSchema}
                      tableData={campaignList}
                      paginationPerPage={15}
                      rowPaddingTop={16}
                      rowPaddingBottom={16}
                      customStyles={customStyles}
                      onRowClicked={(item) =>
                        CampaignClick(item.campaign_data.campaign_id)
                      }
                      pagination
                    />
                  </div>
                </>
              ) : (
                <div className="fallback">
                  <FallBack
                    heading={"No live campaigns"}
                    title={"Launch a new campaign"}
                  />

                  <div className="m-t-15">
                    <PrimaryCta
                      third
                      fontSize={"font_size_regular"}
                      className="create-campaign"
                      text={"Create Campaign"}
                      onClick={() => createCampaign()}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignListingScreen;
