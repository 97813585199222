import React from 'react'
import {CustomText, Image, LinkCta } from '../components';


const OnboardingScreen2 = function(props){

  return (
    <div className="main-container">
      <div className="onboard-inner-container">
        <Image
          source={require("../resources/images/logo.png")}
          height={80}
        />

        <div className="m-t-16">
          <CustomText
            h1
            text={"One Last Step"}
            textColor={"text_color_primary"}
            textAlign={'text_align_center'}
            fontWeight = {'rubik_regular'}
          />
        </div>
        <div className='m-t-12 '>
        <CustomText
            h3
            text={"Please validate your account by clicking the link in the email we just sent to"}
            textColor={"text_color_secondary"}
            fontSize={'font_size_2xl'}
            fontWeight = {'rubik_regular'}
            textAlign={'text_align_center'}
          />

          <CustomText
            h3
            text={" brijesh@rocketmedia.in"}
            textColor={"text_color_secondary"}
            fontSize={'font_size_2xl'}
            fontWeight = {'rubik_regular'}
            textAlign={'text_align_center'}
          />
        </div>
        <div className='m-t-50'>
        <Image
          source={require("../resources/images/email-Icon.png")}
          height={100}
          width={100}
        />
        </div>

        <div className = 'm-t-50'>
            <LinkCta text = {'BROWSE FAQS'}/>
        </div>
      </div>
    </div>
  )
}

export default OnboardingScreen2;
