import React from "react";
import {
  TransitionHistory,
  CustomText,
  FallBack,
  CustomTextInput,
  PrimaryCta,
} from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useState, useEffect } from "react";
import Core from "../../common/clientSdk";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../loader";
import { useSnackbar } from "react-simple-snackbar";
import { useSelector } from "react-redux";
import utils from "../../common/utils";
import CustomTable from "../../components/customTable";
import Papa from "papaparse";
import exportToCSV from "../../common/exportToCSV";
import { ToastContainer, toast } from "react-toastify";
import CompanySettingModal from "../companyDetails/addressDetailVerify";
import { useNavigate } from "react-router-dom";

const limit = 1;
const TransactionHistoryScreen = React.memo(({ location }) => {
  const [start, setStart] = useState(0);
  const [next, setNext] = useState(10);
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPay, setLoadingPay] = useState(false);
  const [addAmount, setAddAmount] = useState("10000");
  const [paymentId, setPaymentId] = useState("");
  const [balance, setBalance] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [address, setAddress] = useState({});
  const [openSnackbar] = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    getOrgDataUser();
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { org_data = {} } = BrandUser.org_deep_data;
  const { user_data = {} } = BrandUser;

  function getOrgDataUser() {
    const params = {
      org_id: brand_deep_data.brand_data.org_id,
    };
    Core.getOrgDataUser(
      getOrgDataUserSuccess,
      getOrgDataUserFailed,
      () => {},
      params
    );
  }

  function getOrgDataUserSuccess(response_data) {
    setAddress(response_data.org_data.address_data.address_premise);
    setBalance(response_data?.org_data?.org_data?.balance);
    setLoading(false);
    getTransactionHistoryList();
  }

  function getOrgDataUserFailed(errorList) {
    openSnackbar(errorList);
  }

  function getTransactionHistoryList() {
    var params = {
      // limit: perPage,
      // start: next,
    };
    Core.getTransactionHistoryList(
      getTransactionHistoryListSuccess,
      getTransactionHistoryListFailed,
      updateProgress,
      params
    );
  }

  function getTransactionHistoryListSuccess(response_data) {
    // if (next == 0 || next == null) {
    //   // API called first time
    //   setHistory(response_data["transaction_list"]);
    // } else {
    //   setHistory([...history, ...response_data["transaction_list"]]);
    // }
    setHistory(response_data["transaction_list"]);
    setNext(response_data?.pagination_data?.next);
  }

  const handlePageChange = (page) => {
    getTransactionHistoryList(page);
  };

  function getTransactionHistoryListFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function postCreateWallet() {
    var params = {
      amount: addAmount,
      org_id: brand_deep_data?.brand_data?.org_id,
    };
    if (minBalanceValidation())
      Core.postCreateWallet(
        postCreateWalletSuccess,
        postCreateWalletFailed,
        (status) => setLoadingPay(status),
        params
      );
  }

  function postCreateWalletSuccess(response_data) {
    createOrder(response_data?.order_data);
  }

  function postCreateWalletFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function redirectContractDetail(assignmentId) {
    navigate(`/contracts/${assignmentId}`);
  }

  const createOrder = (order_data) => {
    // Make an API call to your server to create a test order
    const options = {
      key: order_data.payment_gateway_id,
      order_id: order_data?.pg_order_id,
      amount: order_data?.amount,
      currency: "INR",
      handler: function (response) {
        getTransactionHistoryList(start, updateProgress);
        getOrgDataUser();
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  function minBalanceValidation() {
    if (addAmount < 5000) {
      toast("Amount should not be less than 5000.");
      return false;
    }
    return true;
  }
  const tableSchema = [
    {
      name: "Contract ID",
      selector: (row) => row["assignment_id"]?.toUpperCase(),

      cell: (data) => (
        <span onClick={() => redirectContractDetail(data["assignment_id"])}>
          {data["assignment_id"] ? data["assignment_id"]?.toUpperCase() : "-"}
        </span>
      ),
    },

    {
      name: "Date",
      selector: (row) => {
        return utils.convertUnixToDate(row.transaction_date);
      },

      name: "Transaction",
      selector: (row) => {},

      maxWidth: "500px",
      cell: (data) => (
        <span>{utils.transactionTextToString(data.transaction_type)}</span>
      ),
    },

    {
      name: "Amount",
      selector: (row) => {
        return "Rs " + row["transaction_amount"];
      },
    },

    {
      name: "Date",
      selector: (row) => {
        return utils.convertUnixToDate(row.transaction_date);
      },

      maxWidth: "200px",
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "var(--general-secondary-color)",
        color: "var(--gray-gray-300)",
        fontSize: "16px",
        fontWeight: 600,
        lineHight: "19.2px",
        border: "none",
      },
    },
    headCells: {
      style: {
        padding: "10px 0px 10px 20px",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: 400,
        lineHight: "19.2px",
        "&:hover": {
          backgroundColor: "var(--general-secondary-color)",
          fontWeight: 600,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "20px",
      },
    },
  };

  let gst_value = (addAmount * 18) / 100;
  let total_pay = Number(addAmount) + Number(gst_value);

  const TransactionCSV = () => {
    const data = history.map((item) => {
      return {
        transaction_id: item.transaction_id,
        transaction_amount: item.transaction_amount,
        transaction_type: item.transaction_type,
        transaction_date: utils.convertUnixToDate(item.transaction_date),
        influencer_id: null,
      };
    });

    return (
      <div>
        <PrimaryCta
          sixth
          textColor={"text_color_primary"}
          iconName={"downloadIcon"}
          fontSize={"font_size_large"}
          className="transaction-csv-btn"
          text={"Export to CSV"}
          onClick={() => exportToCSV(data)}
        />
      </div>
    );
  };

  function checkAddressThenPayment() {
    if (org_data.pan_number && address) {
      postCreateWallet();
    } else {
      openModal();
    }
  }

  function openModal() {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div className="page-container-campaignList">
          <>
            <div className="m-l-20">
              <CustomText
                p
                text={"Wallet"}
                textColor={"text_color_primary"}
                className="button2 m-b-8"
              />
              <div className="wallet-screen-add-inner-container">
                <div className="wallet-screen-add-balance">
                  <div>
                    <CustomText
                      p
                      text={"Enter Amount"}
                      textColor={"text_color_primary"}
                      className="button2 m-b-10"
                    />
                    <CustomTextInput
                      className="update_input_text "
                      value={addAmount}
                      onChange={(e) => {
                        const value = e.target.value;
                        const newValue = value.replace(/[^\d]/g, "");
                        setAddAmount(newValue);
                      }}
                      type="number"
                    />
                  </div>

                  <div>
                    <PrimaryCta
                      fifth
                      onClick={() => checkAddressThenPayment()}
                      className="wallet-screen-add-btn"
                      fontSize={"font_size_large"}
                      text={"Add Balance"}
                      isLoading={loadingPay}
                      disabled={addAmount == 0 ? true : false}
                    />
                  </div>
                </div>
                <div className="amount-balance-section">
                  <CustomText
                    p
                    text={"Amount Balance -"}
                    textColor={"text_grey_400_color"}
                    className="Caption-3"
                  />
                  {loading ? (
                    <CustomText p text={`-`} className="Caption-3" />
                  ) : (
                    <CustomText
                      p
                      text={`\u00A0  ₹${balance}`}
                      textColor={
                        balance <= 1000 ? "text_color_red" : "text_color_green"
                      }
                      className="Caption-3"
                    />
                  )}
                </div>
                <div className="amount-balance-section-detail">
                  <CustomText
                    p
                    text={"Amount  "}
                    textColor={"text_color_primary"}
                    className="subtitle1 w-129"
                  />
                  <CustomText
                    p
                    text={addAmount == 0 ? "-" : addAmount}
                    textColor={"text_grey_400_color"}
                    className="button1"
                  />
                </div>
                <div className="amount-balance-section-detail">
                  <CustomText
                    p
                    text={"GST (18%)  "}
                    textColor={"text_color_primary"}
                    className="subtitle1 w-129"
                  />
                  <CustomText
                    p
                    text={gst_value == 0 ? "-" : gst_value}
                    textColor={"text_grey_400_color"}
                    className="button1"
                  />
                </div>
                <div className="amount-balance-section-detail">
                  <CustomText
                    p
                    text={"Total Amount "}
                    textColor={"text_color_primary"}
                    className="subtitle1 w-129"
                  />
                  <CustomText
                    p
                    text={total_pay == 0 ? "-" : total_pay}
                    textColor={"text_grey_400_color"}
                    className="button1 "
                  />
                </div>
              </div>
            </div>

            <div style={{ padding: 15 }}>
              <CustomText
                p
                text={"* The amount added in wallet is non-refundable"}
                textColor={"text_color_primary"}
                className="subtitle1 "
              />
            </div>
            <div className="campaignlist-header m-t-30 m-r-32">
              <CustomText
                p
                text={"Transaction History"}
                textColor={"text_color_primary"}
                fontSize={"font_size_2xl"}
                className="typographyH7"
              />
              <div>
                <TransactionCSV />
              </div>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <div className="custom-table">
                <CustomTable
                  className="CustomTable"
                  tableSchema={tableSchema}
                  tableData={history}
                  paginationPerPage={15}
                  rowPaddingTop={16}
                  rowPaddingBottom={16}
                  // progressPending={loading}
                  customStyles={customStyles}
                  // paginationServer
                  // paginationTotalRows={next}
                  // onChangeRowsPerPage={getTransactionHistoryList}
                  // onChangePage={handlePageChange}
                  pagination
                />
              </div>
            )}
          </>
        </div>
      </div>
      {modalIsOpen && <CompanySettingModal closeModal={closeModal} />}
    </div>
  );
});

export default TransactionHistoryScreen;
