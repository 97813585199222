import React, { useState, useEffect } from "react";

import Modal from "react-modal";
import { CustomText, PrimaryCta, UnderLinedLinkCta } from "../../components";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";
import { useNavigate } from "react-router-dom";
import Utils from "../../common/utils";
import Icon from "../../components/core_component/icon";
import { useSnackbar } from "react-simple-snackbar";
import { useSelector } from "react-redux";
import ReactGA from "react-ga";
import { ToastContainer, toast } from "react-toastify";
function MyCreditsScreen({ closeModal }) {
  const [credits, setCredits] = useState({});
  const [creditsDetail, setCreditsDetail] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [creditId, setCreditId] = useState("001");
  const [orgData, setOrgData] = useState({});
  const [address, setAddress] = useState("");
  const [openSnackbar] = useSnackbar();
  var navigate = useNavigate();

  useEffect(() => {
    getCreditList(updateProgress);
    getOrgDataUser(updateProgress);
  }, []);
  useEffect(() => {
    getCreditDetail(updateProgress);
  }, []);
  function updateProgress(status) {
    setLoading(status);
  }

  var redux_selector = useSelector((state) => {
    return {
      Auth: state.BrandUserAuthReducer,
      UserData: state.BrandUserDataReducer,
    };
  });

  function getOrgDataUser(progress_cb) {
    const params = {
      org_id: redux_selector?.UserData?.user_data.org_id,
    };
    Core.getOrgDataUser(
      getOrgDataUserSuccess,
      getOrgDataUserFailed,
      progress_cb,
      params
    );
  }

  function getOrgDataUserSuccess(response_data) {
    setOrgData(response_data.org_data.org_data);
    setAddress(response_data.org_data.address_data.address_pincode);
    setLoading(false);
  }

  function getOrgDataUserFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function getCreditList(progress_cb) {
    var params = {};
    Core.getCreditList(
      getCreditListSuccess,
      getCreditListFailed,
      progress_cb,
      params
    );
  }

  function getCreditListSuccess(response_data) {
    setCredits(response_data.credit_list);
    setLoading(false);
  }

  function getCreditListFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function getCreditDetail(progress_cb) {
    var params = {};
    Core.getCreditDetail(
      getCreditDetailSuccess,
      getCreditDetailFailed,
      progress_cb,
      params
    );
  }

  function getCreditDetailSuccess(response_data) {
    setCreditsDetail(response_data);
    setLoading(false);
  }

  function getCreditDetailFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function postCreditOrder(progress_cb) {
    var params = {
      c_id: creditId,
      redirect_url: window.location.origin + "/payment_success",
      platform: "web",
    };
    Core.postCreditOrder(
      postCreditOrderSuccess,
      postCreditOrderFailed,
      progress_cb,
      params
    );
  }

  function postCreditOrderSuccess(response_data) {
    const pgOrderId = response_data.credit_order_data.pg_order_id;
    redirectToPaymentPage(pgOrderId);
    setLoading(false);
  }

  function postCreditOrderFailed(errorList) {
    toast.error(errorList);
  }

  const phonePayGateWay = process.env.REACT_APP_API_PHONE_PAY_URL;

  function redirectToPaymentPage(pgOrderId) {
    // const paymentUrl = `https://mercury-t2.phonepe.com/transact/pg?token=${pgOrderId}`;
    const paymentUrl = `${phonePayGateWay}${pgOrderId}`;
    window.location.href = paymentUrl;
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "var(--general-secondary-color)",
      borderColor: "var( --blue-blue-500)",
      textAlign: "center",
      zIndex: "5",
      height: "82%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      minWidth: "400px",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.86)",
      zIndex: 10,
    },
  };

  const { pan_number } = orgData;

  function kycVerifyCheck() {
    postCreditOrder(updateProgress);
  }

  return (
    <>
      <Modal isOpen={true} onRequestClose={closeModal} style={customStyles}>
        <div onClick={closeModal} className="close-btn-icon">
          <Icon iconName="CloseRoundButtonIcon" />
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="credits-screen-header">
              <div className="m-b-15">
                <CustomText
                  p
                  text={"Buy Credits"}
                  fontSize={"font_size_xl"}
                  textColor={"text_color_Primary"}
                  fontWeight={"rubik_regular"}
                />
              </div>

              <div style={{ marginBottom: 5 }} className="all-credit-data">
                <CustomText
                  p
                  text={
                    creditsDetail.credit_data ? creditsDetail.credit_data : "0"
                  }
                  fontSize={"font_size_paragraph"}
                  textColor={"text_color_primary"}
                  fontWeight={"rubik_regular"}
                />

                <CustomText
                  p
                  text={" credits left"}
                  fontSize={"font_size_paragraph"}
                  textColor={"text_color_primary"}
                  fontWeight={"rubik_regular"}
                />
              </div>
              <div className="m-b-15">
                <UnderLinedLinkCta
                  fontSize={"font_size_small"}
                  text={"History"}
                  onClick={() => navigate("/credit-transaction")}
                />
              </div>
              <div className="all-credit-data-info">
                <CustomText
                  p
                  text={"1 Invite consumes 1 credits"}
                  fontSize={"font_size_paragraph"}
                  textColor={"text_color_primary"}
                  fontWeight={"rubik_regular"}
                />
                <CustomText
                  p
                  text={"1 Counter consumes 1 credits"}
                  fontSize={"font_size_paragraph"}
                  textColor={"text_color_primary"}
                  fontWeight={"rubik_regular"}
                />
                <CustomText
                  p
                  text={"1 Campaign consumes 10 credits"}
                  fontSize={"font_size_paragraph"}
                  textColor={"text_color_primary"}
                  fontWeight={"rubik_regular"}
                />
              </div>
            </div>
            <div className="all-credit-card-warper">
              {credits.basic &&
                credits.basic.map((item) => {
                  return (
                    <div
                      onClick={() => setCreditId(item.c_id)}
                      className={
                        creditId == item.c_id
                          ? "bottom-credit-card_active"
                          : "bottom-credit-card"
                      }
                    >
                      <CustomText
                        p
                        text={`${item.c_quantity} Credits`}
                        fontSize={"font_size_paragraph"}
                        textColor={"text_color_primary"}
                        fontWeight={"rubik_regular"}
                      />

                      <div className="flex-row">
                        <CustomText
                          p
                          text={`Rs. ${(item.c_quantity * item.c_value).toFixed(
                            0
                          )}`}
                          fontSize={"font_size_paragraph"}
                          textColor={"text_color_primary"}
                          fontWeight={"rubik_regular"}
                        />
                        <CustomText
                          p
                          text={` (Rs. ${item.c_value}/credits)`}
                          fontSize={"font_size_paragraph"}
                          textColor={"text_color_primary"}
                          fontWeight={"rubik_regular"}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            <div style={{ paddingTop: 10 }}>
              <CustomText
                p
                text={"18% GST will be applicable"}
                fontSize={"font_size_paragraph"}
                textColor={"text_color_primary"}
                fontWeight={"rubik_regular"}
              />
            </div>
            <div className="m-t-15 full-width">
              <PrimaryCta
                fontSize={"font_size_large"}
                fullWidth={true}
                text={"Buy Credits"}
                isLoading={isLoading}
                disabled={Utils.isNull(creditId)}
                onClick={() => kycVerifyCheck()}
              />
            </div>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
          </>
        )}
      </Modal>
    </>
  );
}

export default MyCreditsScreen;
