import React, { useEffect, useState } from "react";
import { AnalyticsCard, CustomText } from "../../components";
import Icon from "../../components/core_component/icon";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";
import Utils from "../../common/utils";

function AnalyticsCampaign({ campaignId, brandId, orgId }) {
  const [isLoading, setLoading] = useState(false);
  const [campaignReport, setCampaignReport] = useState("");
  const [campaignAnalytic, setCampaignAnalytic] = useState("");
  const [campaignContentAnalytic, setCampaignContentAnalytic] = useState("");

  useEffect(() => {
    getCampaignReport();
    campaignAnalytics();
    campaignContentAnalytics();
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  function getCampaignReport() {
    const params = {
      campaign_id: campaignId,
    };
    Core.getCampaignReport(
      getCampaignReportSuccess,
      getCampaignReportFailed,
      updateProgress,
      params
    );
  }

  function getCampaignReportSuccess(response_data) {
    setCampaignReport(response_data);
    setLoading(false);
  }

  function getCampaignReportFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function campaignAnalytics() {
    const params = {
      campaign_id: campaignId,
    };
    Core.campaignAnalytics(
      campaignAnalyticsSuccess,
      campaignAnalyticsFailed,
      updateProgress,
      params
    );
  }

  function campaignAnalyticsSuccess(response_data) {
    setCampaignAnalytic(response_data.analytics_data);
    setLoading(false);
  }

  function campaignAnalyticsFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function campaignContentAnalytics() {
    const params = {
      org_id: orgId,
      brand_id: brandId,
      campaign_id: campaignId,
    };
    Core.campaignContentAnalytics(
      campaignContentAnalyticsSuccess,
      campaignContentAnalyticsFailed,
      updateProgress,
      params
    );
  }

  function campaignContentAnalyticsSuccess(response_data) {
    setCampaignContentAnalytic(response_data.campaign_content_analytics_data);
    setLoading(false);
  }

  function campaignContentAnalyticsFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  let Engagement =
    Number(campaignContentAnalytic.total_like_count) +
    Number(campaignContentAnalytic.total_comment_count);
  let avgEngagement = (Engagement / Number(campaignReport.total_reach)) * 100;
  let cpfValue = campaignReport.total_spent / campaignReport.total_reach;
  let cpEValue = campaignReport.total_spent / Engagement;
  console.log("cpEValue", cpEValue);
  let cpmValue =
    campaignReport.total_spent / (campaignReport.total_view * 1000);
  console.log("cpfValue.typeof", cpfValue);
  function ContentReachData() {
    return (
      <div className="analytics-social-media-data">
        <div className="influencer_address_detail m-b-16">
          <div className="flex-row">
            {" "}
            <Icon iconName="heartIcon" />
            <CustomText
              p
              text={"Like"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
          </div>

          <CustomText
            p
            text={"76k"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        </div>
        <div className="influencer_address_detail m-b-16">
          <div className="flex-row">
            <Icon iconName="commentIcon" />
            <CustomText
              p
              text={"Comment"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
          </div>

          <CustomText
            p
            text={"89k"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        </div>
        <div className="influencer_address_detail m-b-16">
          <div className="flex-row">
            {" "}
            <Icon iconName="viewEyes" />{" "}
            <CustomText
              p
              text={"View Rate"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />{" "}
          </div>

          <CustomText
            p
            text={"89k"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        </div>
        <div className="influencer_address_detail">
          <div className="flex-row">
            <Icon iconName="rupeeIcon" />
            <CustomText
              p
              text={"CPV"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
          </div>

          <CustomText
            p
            text={"90k"}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Inter_medium_500"}
            height={"line_height_19_2"}
          />
        </div>
      </div>
    );
  }

  console.log("campaignAnalytic.approved_bid_count", campaignAnalytic);

  console.log("campaignReport.total_view", campaignReport.total_view);
  return (
    <div style={{ minHeight: 500 }} className="">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="campaignlist-header m-t-30 ">
              <CustomText
                p
                text={"Campaign analytics"}
                textColor={"text_color_primary"}
                fontSize={"font_size_2xl"}
                className="typographyH7"
              />
            </div>
            <div className="analytics-card-container ">
              <AnalyticsCard
                title={"Total Spent"}
                value={`Rs ${
                  campaignReport.total_spent ? campaignReport.total_spent : 0
                }`}
                iconName="rupeeIcon"
              />
              <AnalyticsCard
                title={"Total Proposals"}
                value={campaignReport.total_bids}
                iconName="heartIcon"
              />
              <AnalyticsCard
                title={"Rejected"}
                value={campaignAnalytic.rejected_bid_count}
                iconName="rejectedIcon"
              />
              <AnalyticsCard
                title={"Finalized"}
                value={Utils.convertToK(campaignReport.total_contract)}
                iconName="approvedIcon"
              />
              <AnalyticsCard
                title={"Live"}
                value={Utils.convertToK(campaignAnalytic.assignment_count)}
                iconName="liveIcons"
              />
            </div>
          </>
          <>
            <div className="campaignlist-header m-t-30 ">
              <CustomText
                p
                text={"Content KPI"}
                textColor={"text_color_primary"}
                fontSize={"font_size_2xl"}
                className="typographyH7"
              />
            </div>
            <div className="analytics-card-container ">
              <AnalyticsCard
                title={"Content"}
                value={Utils.convertToK(campaignAnalytic.assignment_count)}
                iconName="AssignmentIconActive"
              />
              <AnalyticsCard
                title={"Total View"}
                value={
                  campaignContentAnalytic.total_play_count
                    ? Utils.convertToK(campaignContentAnalytic.total_play_count)
                    : 0
                }
                iconName="playIcon"
              />
              <AnalyticsCard
                title={"Total Reach"}
                value={Utils.convertToK(campaignReport.total_reach)}
                iconName="reachIcon"
              />
              <AnalyticsCard
                title={"Total Like"}
                value={
                  campaignContentAnalytic.total_like_count
                    ? Utils.convertToK(campaignContentAnalytic.total_like_count)
                    : 0
                }
                iconName="thumbUpIcon"
              />
              <AnalyticsCard
                title={"Average Engagement"}
                value={
                  avgEngagement > 0
                    ? Utils.convertToK(avgEngagement.toFixed(2))
                    : 0
                }
                iconName="engagementIcon"
              />
            </div>
          </>
          <>
            <div className="campaignlist-header m-t-30">
              <CustomText
                p
                text={"Cost KPI"}
                textColor={"text_color_primary"}
                fontSize={"font_size_2xl"}
                className="typographyH7"
              />
            </div>
            <div className="analytics-card-container ">
              <AnalyticsCard
                title={"CPM"}
                value={`Rs ${cpmValue.toFixed(2)}`}
                iconName="rupeeIcon"
              />
              <AnalyticsCard
                title={"CPE"}
                value={`Rs ${
                  cpEValue < Infinity
                    ? Utils.convertToK(cpEValue).toFixed(2)
                    : "0"
                }`}
                iconName="rupeeIcon"
              />
              <AnalyticsCard
                title={"CPF"}
                value={`Rs ${cpfValue > 0 ? cpfValue.toFixed(2) : 0}`}
                iconName="rupeeIcon"
              />
            </div>
          </>
        </>
      )}
    </div>
  );
}

export default AnalyticsCampaign;
