import React, { useEffect, useState } from "react";
import { CustomText, PrimaryCta } from "../../components";

function PaymentSuccess() {
  const [countdown, setCountdown] = useState(3);
  useEffect(() => {
    if (countdown === 0) {
      window.location.href = "/";
    } else {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [countdown]);
  return (
    <div>
      <div className="success-container">
        <div className="success-box">
          <CustomText
            p
            fontSize={"font_max_size"}
            text={countdown}
            textColor={"text_color_primary"}
            fontWeight={"rubik_regular"}
          />
          <CustomText
            p
            fontSize={"font_max_size"}
            text={"Payment Successful!"}
            textColor={"text_color_primary"}
            fontWeight={"rubik_regular"}
          />
          <CustomText
            p
            fontSize={"font_size_xl"}
            text={"Thank you for your payment."}
            textColor={"text_color_primary"}
            fontWeight={"rubik_regular"}
          />

          <p className="success-text">
            You will be redirected to the home page in <span>{countdown}</span>
            &nbsp;seconds.
          </p>
          <p className="success-text">
            If you are not redirected, <a href="/">click here</a>.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccess;
