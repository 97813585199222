import React, { useState, useEffect } from "react";
import { CustomButton, Image, PrimaryCta } from "../../components";
import * as requestPermission from "../../common/firebase";
import WebStorage from "../../common/web_storage";
const NotificationPrompt = () => {
  const [permission, setPermission] = useState(Notification.permission);
  const [deferred, setDeferred] = useState(false);

  useEffect(() => {
    setPermission(Notification.permission);
    setDeferred(WebStorage.getDataFromWebStorage("NOTIFICATION_POP"));
  }, []);

  const handleAllow = () => {
    if (window.PushManager) {
      Notification.requestPermission().then((permission) => {
        console.log("permission", permission);
        setPermission(permission);
        setDeferred(true);
        requestPermission();
      });
    }
  };

  const handleLater = () => {
    setDeferred(true);

    WebStorage.saveDataInWebStorage("NOTIFICATION_POP", true);
  };

  if (permission === "granted" || deferred) {
    return null; // Don't show the prompt if permission is granted or deferred
  }

  console.log("notification prompt");

  return (
    <div className="notification-prompt">
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* <div className="m-r-20 cursor-pointer">
          <Image
            source={"../assets/images/reelax-new-logo.svg"}
            height="40px"
          />
        </div> */}
        <p>Get notifications on important updates from Influencers</p>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <button className="cancel-btn-notification " onClick={handleLater}>
          Later
        </button>
        <button className="allow-btn-notification" onClick={handleAllow}>
          Allow
        </button>
      </div>
    </div>
  );
};

export default NotificationPrompt;
