"use-strict";

//Dependencies
import React, { useState, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomText,
  Image,
  CustomTextInput,
  PrimaryCta,
  LinkCta,
  UnderLinedLinkCta,
} from "../../components";

const TermsScreen = function (props) {
  return (
    <div className="main-container">
      <div className="text-screen-container">
        <h1>Terms of Service</h1>
        <p>
          Welcome to Reelax, an creator marketing app that connects brands with
          social media influencers to create effective marketing campaigns. By
          accessing or using our app, you agree to the following terms and
          conditions:
        </p>

        <h3>Account Creation</h3>
        <p>
          To use our app, you must create an account by providing your name,
          email address, and other required information. You must be at least 18
          years old to create an account.
        </p>

        <h3>Brand Campaigns</h3>
        <p>
          Brands can create campaigns on our app and invite influencers to place
          bids. Bids can be accepted or rejected by the brand at their
          discretion.
        </p>

        <h3>Creator Proposal</h3>
        <p>
          Creators can send proposals on brand campaigns and offer their
          services. Brands can accept or reject bids at their discretion.
        </p>

        <h3>Content Sharing</h3>
        <p>
          If a bid is accepted, influencers are required to share the brand's
          content on their social media profiles as per the agreed-upon terms.
        </p>

        <h3>Payments</h3>
        <p>
          Brands are required to pay influencers for their services through our
          app. Payments will be made upon completion of the campaign.
        </p>

        <h3>User Conduct</h3>
        <p>
          Users must comply with our community guidelines and refrain from
          engaging in any illegal or abusive activities.
        </p>

        <h3>Disclaimer</h3>
        <p>
          We are not responsible for any content shared by influencers on their
          social media profiles or any damages caused by their actions.
        </p>

        <h3>Modifications</h3>
        <p>
          We reserve the right to modify or update these terms at any time. Your
          continued use of our app after such changes will constitute your
          acceptance of the updated terms.
        </p>

        <h3>Refunds and cancellation</h3>
        <p>We do not provide any refunds of any of the payment made to us</p>
        <h3>Contact</h3>
        <p>For any quries contact us at support@getreelax.com</p>
        <p>
          Registered Address: Wework DLF Forum Infinity Tower C,DLF Cyber City,
          DLF Phase 3, Sec25A,DLF QE,Dlf Qe,Gurgaon-122002,Haryana
        </p>
      </div>
    </div>
  );
};

export default TermsScreen;
