// Info: Component specific Styles
//////////////////////////// Module Exports START /////////////////////////////
module.exports = function (
  rowTextColor,
  altRowBgColor,
  altTextColor,
  headerBgColor,
  shadowColor,
  rowBgColor,
  dense,
  rowBorderColor,
  headBorderColor,
  rowBorderType,
  headBorderType,
  footerBgColor,
  footerBorderType,
  footerBorderColor,
  rowPaddingTop,
  rowPaddingBottom,
  headPaddingTop,
  headPaddingBottom
) {
  return {
    rows: {
      style: {
        // maxHeight: '24px', //minHeight: '72px', // override the row height
        justifyContent: "left",
        color: rowTextColor,
        paddingTop: `${rowPaddingTop}px`,
        paddingBottom: `${rowPaddingBottom}px`,
        // paddingLeft:'16px',
        boxShadow: shadowColor ? `0px -2px 8px ${shadowColor}` : "none",
        //fontFamily: 'Poppins-Regular',
        backgroundColor: rowBgColor ? rowBgColor : "white",
        borderBottom: rowBorderColor
          ? `1px ${
              rowBorderType ? rowBorderType : "solid"
            } ${rowBorderColor}!important`
          : "none!important",
        borderStyle: "rowBorderType",
        "&:nth-of-type(odd)": {
          backgroundColor: altRowBgColor,
        },
        "&:nth-last-of-type(1)": {
          // fontFamily: 'Poppins-Semibold',
          // boxShadow: '2px 2px #c6c6c6',
          // backgroundColor: footerBgColor ?'black', : 'none'
          // boxShadow: '0px 3px 3px red, -1em 0 0.4em olive',
          // borderStyle: footerBorderType ? footerBorderType : rowBorderType,
          // marginBottom: shadowColor ? '17px' : null
        },
        // '&:nth-last-of-type(2)': {
        //   //marginBottom: '2px',
        //   //boxShadow: '1px 1px #c6c6c6',
        // },
        "&:hover": {
          backgroundColor: "var(--general-secondary-color)",
        },
      },
    },

    head: {
      style: {
        padding: "0px",
        paddingTop: `${headPaddingTop}px`,
        paddingBottom: `${headPaddingBottom}px`,
        boxShadow: shadowColor
          ? `0px -2px 8px ${shadowColor}`
          : "none!important",
        borderBottom: headBorderColor
          ? `1px ${
              headBorderType ? headBorderType : "solid"
            } ${headBorderColor}!important`
          : "none !important",
      },
    },

    headCells: {
      style: {
        padding: "0px",
        fontFamily: "Inter-Semibold",
        backgroundColor: headerBgColor,
      },
    },

    cells: {
      style: {
        // override the cell padding for data cells
        // paddingLeft: '8px',
        // paddingRight: '8px',
        //boxShadow: shadowColor ? shadowColor : 'none',
        //width: '150px',
        //fontSize: 'px',
        padding: "0px",
      },
    },

    footer: {
      head: {
        style: {
          // padding:'0px',
          // paddingLeft:'16px',
          boxShadow: shadowColor
            ? `0px -2px 8px ${shadowColor}`
            : "none!important",
          borderBottom: headBorderColor
            ? `1px ${
                headBorderType ? headBorderType : "solid"
              } ${headBorderColor}!important`
            : "none!important",
        },
      },

      rows: {
        style: {
          // paddingLeft:"16px",
          boxShadow: shadowColor
            ? `0px -2px 8px ${shadowColor}!important`
            : "none",
          backgroundColor: footerBgColor ? footerBgColor : rowBgColor,
          borderBottom: footerBorderColor
            ? `2px ${
                footerBorderType ? footerBorderType : "solid"
              } ${footerBorderColor}!important`
            : "none",
          borderStyle: footerBorderType ? footerBorderType : rowBorderType,
        },
      },
      cells: {
        style: {
          padding: "0px",
        },
      },
    },
  };
}; //////////////////////////// Module Exports END //////////////////////////////
