"use-strict";

//Dependencies
import React, { useState, lazy, Suspense, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomText,
  Image,
  CustomTextInput,
  PrimaryCta,
  LinkCta,
  UnderLinedLinkCta,
} from "../../components";

// Import Redux State (Private scope)
import { useDispatch, useSelector } from "react-redux";

import * as Actions from "../../redux/actions";

//import validator from 'validator'

// Core Sdk (Private Scope)
import Core from "../../common/clientSdk.js";

// Core Sdk (Private Scope)
import Utils from "../../common/utils.js";
import validator from "validator";
import WebStorage from "../../common/web_storage";
import { ORG_ID_KEY } from "../../common/web_storage_keys";
import { Loader } from "../loader";
import { useSnackbar } from "react-simple-snackbar";
import ReactGA from "react-ga";
import LeftSideSignup from "./leftSideSignup.js";

const LoginScreen = function (props) {
  const [loading, set_loading] = useState(false);
  const [email, set_email] = useState(null);
  const [password, set_password] = useState(null);
  //const [error, set_error] = useState(null);
  const [openSnackbar] = useSnackbar();

  var navigate = useNavigate();
  var redux_selector = useSelector((state) => {
    return state;
  });

  var redux_dispatch = useDispatch();

  // useEffect(() => {
  //   ReactGA.set({
  //     org_id: 123,
  //   });

  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  /********************************************************************
  Brand Login

  @param {Function} progress_cb - manage status of screen

  No return
  *********************************************************************/
  function brandLogin(progress_cb) {
    var params = {
      user_data: JSON.stringify({
        email: email,
        password: password,
      }),
    };

    Core.brandLogin(brandLoginSuccess, brandLoginFailed, progress_cb, params);
  }

  /********************************************************************
  Brand Login success

  @param {Set} response_data - consisting user_data and brand_data

  No return
  *********************************************************************/
  function brandLoginSuccess(response_data) {
    //update brand user data in redux
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_DATA,
      payload: { user_data: response_data.user_data },
    });

    redux_dispatch({
      type: Actions.UPDATE_ORG_DATA,
      payload: { org_deep_data: response_data.org_data },
    });

    // update brand data in redux
    redux_dispatch({
      type: Actions.UPDATE_BRAND_DATA,
      payload: { brand_deep_data: response_data.brand_list[0] },
    });

    // Update Auth token
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_AUTH,
      payload: { auth_token: response_data.auth_id },
    });
    WebStorage.saveDataInWebStorage(
      ORG_ID_KEY,
      response_data.org_data.org_data.org_id
    );
    if (response_data.brand_list.length == 0) {
      navigate("/add-brand-details");
    } else {
      navigate("/");
    }
    googleAnalytics();
  }

  /********************************************************************
  Brand Login Failed

  @param {string} error - consisting Error List

  No return
  *********************************************************************/
  function brandLoginFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
    //openSnackbar(errorList);
  }

  /********************************************************************
  Screen Progress

  @param {Boolean} status - true/false

  No return
  *********************************************************************/
  function updateProgress(status) {
    set_loading(status);
  }

  function googleAnalytics() {
    ReactGA.event({
      category: "User",
      action: "REELAX_ADS_WEB_LOGIN",
    });
  }

  // Return Screen

  return (
    <div className="main-container">
      <div className="content-container">
        <LeftSideSignup />
        <div className="auth-right-container">
          <div className="inner-left-container">
            <div className="inner-left-container-bind">
              <Image
                source={"../assets/images/reelax-new-logo.svg"}
                height={49}
              />
              <div className="flex-row  m-t-8">
                <div>
                  <CustomText
                    h5
                    className="h5text"
                    //fontSize={"font_size_25"}
                    text={`Login to Reelax`}
                    textColor={"text_color_primary"}
                    //fontWeight={"Poppins_regular_large"}
                  />
                </div>
                &nbsp;
              </div>
              <div className="m-t-16">
                <CustomText
                  h5
                  fontSize={"font_size_large"}
                  text={`Email`}
                  textColor={"text_grey_400_color"}
                  fontWeight={"Poppins_regular_large"}
                  height={"line_height_19_2"}
                />

                <div className="m-t-4 full-width">
                  <CustomTextInput
                    className="custom-input-text"
                    placeholder="Enter email"
                    onChange={(e) => set_email(e.target.value)}
                  />
                </div>
              </div>
              <div className="m-t-16">
                <CustomText
                  h5
                  fontSize={"font_size_large"}
                  text={`Password`}
                  textColor={"text_grey_400_color"}
                  fontWeight={"Poppins_regular_large"}
                  height={"line_height_19_2"}
                />

                <div className="m-t-4 full-width">
                  <CustomTextInput
                    className="custom-input-text"
                    type="password"
                    placeholder="Password"
                    onChange={(e) => set_password(e.target.value)}
                  />
                </div>
              </div>
              <div className="m-t-4 forgot-password">
                <LinkCta
                  p
                  textColor={"text_color_forgot"}
                  text={"Forgot password ?"}
                  onClick={() => {
                    navigate("/forgot-password");
                    // navigate("verifyEmail/${request_id}");
                  }}
                />
              </div>

              <div className="m-t-24 full-width">
                <PrimaryCta
                  fontSize={"font_size_large"}
                  fullWidth={true}
                  text={"Log In"}
                  onClick={() => brandLogin(updateProgress)}
                  isLoading={loading}
                  disabled={Utils.isNull(email) || Utils.isNull(password)}
                />
              </div>
              <div className="m-t-6 signup-login-text">
                <CustomText
                  p
                  fontSize={"font_size_small"}
                  text={`If you don’t have an account`}
                  textColor={"text_color_primary"}
                  fontWeight={"rubik_regular"}
                />
                &nbsp;
                <LinkCta
                  fontSize={"font_size_small"}
                  text={"Signup here"}
                  textColor={"text_color_register"}
                  onClick={() => {
                    navigate("/signup");
                  }}
                />
              </div>

              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 40,
                }}
              >
                <UnderLinedLinkCta
                  fontSize={"font_size_small"}
                  text={"Terms of Service"}
                  textColor={"text_color_register"}
                  onClick={() => {
                    navigate("/terms");
                  }}
                />
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <CustomText
                    p
                    fontSize={"font_size_small"}
                    text={` and `}
                    textColor={"text_color_primary"}
                    fontWeight={"rubik_regular"}
                  />
                </div>

                <UnderLinedLinkCta
                  fontSize={"font_size_small"}
                  text={"Privacy Policy"}
                  textColor={"text_color_register"}
                  onClick={() => {
                    navigate("/privacy-policy");
                  }}
                />
              </div> */}
              <div
                className="m-t-40"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}>
                <CustomText
                  p
                  fontSize={"font_size_small"}
                  text={`Powered By Bridgeness Technologies Pvt Ltd`}
                  textColor={"text_color_primary"}
                  fontWeight={"rubik_regular"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
