import React from "react";
import { CustomText, Image } from "../../components";
import Icon from "../../components/core_component/icon";

const LeftSideSignup = function (props) {
  // Return Screen
  const signUpList = [
    // {
    //   icon: "greenTickIcon",
    //   text: "India' first auction based influencer marketing platform",
    // },
    {
      icon: "greenTickIcon",
      text: "More than 2.3 lakhs KYC verified Influencers registered on Reelax Platform",
    },
    {
      icon: "greenTickIcon",
      text: "12+ Categories, Gender, language and age ground level targeting",
    },
    {
      icon: "greenTickIcon",
      text: "Complete software that manages contracts payments and payouts at one place",
    },
    {
      icon: "greenTickIcon",
      text: "You pay only you receive the desired content",
    },
  ];
  return (
    <>
      <div className="auth-left-sign-container">
        <div className="auth-left-sign-inner">
          <CustomText
            h5
            fontSize={"font_size_25"}
            text={`India' first best based influencer marketing platform`}
            textColor={"text_color_light"}
            fontWeight={"Poppins_regular_large"}
          />
          {signUpList.map((item) => {
            return (
              <div className="sign-up-text-container">
                <div>
                  <Icon iconName={item.icon} />
                </div>

                <CustomText
                  p
                  fontSize={"font_size_large"}
                  text={item.text}
                  textColor={"text_color_light"}
                  fontWeight={"Poppins_regular_medium"}
                  noOfLine={5}
                />
              </div>
            );
          })}
          <Image
            className="sign-img-right"
            source={"../assets/images/add-brand-details.svg"}
            height={400}
            width={443}
          />
        </div>
      </div>
    </>
  );
};
export default LeftSideSignup;
