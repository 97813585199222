import React, { useEffect, useState } from "react";
import { CustomText, Image, Video, ViewCards } from "../../components";
import Core from "../../common/clientSdk";
import utils from "../../common/utils";
import Icon from "../../components/core_component/icon";
import { useSelector } from "react-redux";
import { Loader } from "../loader";
import { useSnackbar } from "react-simple-snackbar";
import Utils from "../../common/utils";
import EditDemographicsScreen from "../editCampaign/editDemographicsScreen";
import EditCampaignDetailsScreen from "../editCampaign/editCampaignDetailsScreen";
import ReactHtmlParser from "react-html-parser";
import Style from "../../resources/style";

function CampaignDetailsScreen(props) {
  const [showFull, setShowFull] = useState(false);
  //const [campaignDetail, setCampaignDetail] = useState({});
  const [selectedPlatform, setSelectedPlatform] = useState();
  const [isLoading, setLoading] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [campaignReport, setCampaignReport] = useState();
  const [modalDemographicsIsOpen, setModalDemographicsIsOpen] = useState(false);
  const [modalCampaignDetailsIsOpen, setModalCampaignDetailsIsOpen] =
    useState(false);
  const [openSnackbar] = useSnackbar();
  const campaignId = props.campaignId;
  const campaignDetail = props.campaignDetail;
  const getCampaignUserDetail = props.getCampaignUserDetail;

  const openDemographicsModal = () => {
    if (modalDemographicsIsOpen == false) {
      setModalDemographicsIsOpen(true);
    } else {
      setModalDemographicsIsOpen(false);
    }
  };

  const closeDemographicsModal = () => {
    setModalDemographicsIsOpen(false);
  };

  const openCampaignDetailsModal = () => {
    if (modalCampaignDetailsIsOpen == false) {
      setModalDemographicsIsOpen(true);
    } else {
      setModalCampaignDetailsIsOpen(false);
    }
  };

  const closeCampaignDetailsModal = () => {
    setModalCampaignDetailsIsOpen(false);
  };

  useEffect(() => {
    //   getCampaignUserDetail(updateProgress);
    getCampaignReport(updateProgress);
  }, []);

  useEffect(() => {
    deliverableData();
  }, [campaignDetail?.content_type_deep_data]);

  function updateProgress(status) {
    setLoading(status);
  }

  const handleClick = (index) => {
    setShowImage((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleVideoClick = (index) => {
    setShowVideo((prevIndex) => (prevIndex === index ? null : index));
  };

  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { brand_data = {} } = brand_deep_data;

  // function getCampaignUserDetail() {
  //   const params = {
  //     brand_id: brand_data.brand_id,
  //     campaign_id: campaignId,
  //   };
  //   Core.getCampaignUserDetail(
  //     getCampaignUserDetailSuccess,
  //     getCampaignUserDetailFailed,
  //     updateProgress,
  //     params
  //   );
  // }

  // function getCampaignUserDetailSuccess(response_data) {
  //   setCampaignDetail(response_data.campaign_data);
  //   setLoading(false);
  // }

  // function getCampaignUserDetailFailed(errorList) {
  //   openSnackbar(errorList.map((item) => item.m).join(", "));
  // }

  function getCampaignReport() {
    const params = {
      campaign_id: campaignId,
    };
    Core.getCampaignReport(
      getCampaignReportSuccess,
      getCampaignReportFailed,
      updateProgress,
      params
    );
  }

  function getCampaignReportSuccess(response_data) {
    setCampaignReport(response_data);
    setLoading(false);
  }

  function getCampaignReportFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  const {
    audience_data = {},
    campaign_data = {},
    influencer_data = {},
    reward_data = {},
    instruction_data = {},
  } = campaignDetail;

  function deliverableData() {
    let newSelectedPlatform = {};
    if (campaignDetail?.content_type_deep_data) {
      campaignDetail?.content_type_deep_data.forEach((item) => {
        if (newSelectedPlatform[item.platform_data.platform_id]) {
          newSelectedPlatform[item.platform_data.platform_id].push(
            item.content_data
          );
        } else {
          newSelectedPlatform[item.platform_data.platform_id] = [
            item.content_data,
          ];
        }
      });
    }
    setSelectedPlatform(newSelectedPlatform);
  }

  function CampaignDetailFun() {
    return (
      <ViewCards title={"Campaign Details"}>
        <div className="campaign-detail-card m-b-12">
          <div className="w-195">
            <CustomText
              p
              text={"Campaign Title:"}
              fontSize={"font_size_large"}
              textAlign={"text_align_left"}
              textColor={"text_color_secondary"}
            />
          </div>
          <div>
            <CustomText
              p
              text={campaign_data.name}
              fontSize={"font_size_large"}
              textAlign={"text_align_right"}
              noOfLine={"twoLines"}
              textColor={"text_color_primary"}
              fontWeight={"500px"}
            />
          </div>
        </div>
        <div className="campaign-detail-card m-b-12">
          <div className="w-195">
            <CustomText
              p
              text={"Campaign Type:"}
              fontSize={"font_size_large"}
              textAlign={"text_align_left"}
              textColor={"text_color_secondary"}
            />
          </div>
          <div>
            <CustomText
              p
              text={Utils.getCampaignType(campaign_data.type)}
              fontSize={"font_size_large"}
              textAlign={"text_align_left"}
              textColor={"text_color_primary"}
            />
          </div>
        </div>
        <div className="campaign-detail-card m-b-12">
          <div className="w-195">
            <CustomText
              p
              text={"Campaign Status:"}
              fontSize={"font_size_large"}
              textAlign={"text_align_left"}
              textColor={"text_color_secondary"}
            />
          </div>
          <div>
            <CustomText
              p
              text={utils.assignmentStatusToString(campaign_data.status)}
              fontSize={"font_size_large"}
              textAlign={"text_align_left"}
              textColor={"text_color_primary"}
            />
          </div>
        </div>
        {/* <div className="campaign-detail-card m-b-12">
          <div className="w-195">
            <CustomText
              p
              text={"Engagement type:"}
              fontSize={"font_size_large"}
              textAlign={"text_align_left"}
              textColor={"text_color_secondary"}
            />
          </div>
          <div>
            <CustomText
              p
              text={utils.engagementType(campaign_data.engagement_type)}
              fontSize={"font_size_large"}
              textAlign={"text_align_left"}
              textColor={"text_color_primary"}
            />
          </div>
        </div> */}
        <div className="campaign-detail-card">
          <div className="w-195">
            <CustomText
              p
              text={"Collaboration Type:"}
              fontSize={"font_size_large"}
              textAlign={"text_align_left"}
              textColor={"text_color_secondary"}
            />
          </div>
          <div>
            <CustomText
              p
              text={campaign_data.collab_type}
              fontSize={"font_size_large"}
              textAlign={"text_align_left"}
              textColor={"text_color_primary"}
            />
          </div>
        </div>
      </ViewCards>
    );
  }

  function AnalyticsFun() {
    return (
      <ViewCards title={"Analytics"}>
        <div className="analytics-container">
          <div>
            <div className="detail-page-inner-card m-b-12">
              <div>
                <CustomText
                  p
                  text={" Total spent:"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
              <div className="m-l-10">
                <CustomText
                  p
                  text={campaignReport?.total_spent}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_primary"}
                  fontWeight={"500px"}
                />
              </div>
            </div>
            <div className="detail-page-inner-card m-b-12">
              <div>
                <CustomText
                  p
                  text={" Total proposals:"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
              <div className="m-l-10">
                <CustomText
                  p
                  text={campaignReport?.total_bids}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_primary"}
                  fontWeight={"500px"}
                />
              </div>
            </div>
            <div className="detail-page-inner-card m-b-12">
              <div>
                <CustomText
                  p
                  text={"Total audience reach:"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
              <div className="m-l-10">
                <CustomText
                  p
                  text={campaignReport?.total_reach.toString()}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_primary"}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="detail-page-inner-card m-b-12">
              <div>
                <CustomText
                  p
                  text={"Total views:"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
              <div className="m-l-10">
                <CustomText
                  p
                  text={campaignReport?.total_view}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_primary"}
                />
              </div>
            </div>
            <div className="detail-page-inner-card m-b-12">
              <div>
                <CustomText
                  p
                  text={"   Total contracts:"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
              <div className="m-l-10">
                <CustomText
                  p
                  text={campaignReport?.total_contract}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_primary"}
                />
              </div>
            </div>
          </div>
        </div>
      </ViewCards>
    );
  }

  function CollaborationRequirementFun() {
    return (
      <ViewCards title={"Collaboration Requirement"}>
        <div className="analytics-container">
          <div>
            <div className="detail-page-inner-card text-wrap m-b-12">
              <div>
                <CustomText
                  p
                  text={"Expiry Date:"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>

              <div className="m-l-10">
                <CustomText
                  p
                  text={utils.convertUnixToDate(campaign_data.end_date)}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_primary"}
                  fontWeight={"500px"}
                />
              </div>
            </div>
            {campaign_data.product_price !== 0 && (
              <div className="detail-page-inner-card m-b-12">
                <div>
                  <CustomText
                    p
                    text={"Product Price:"}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
                <div className="m-l-10">
                  <CustomText
                    p
                    text={campaign_data.product_price}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_primary"}
                  />
                </div>
              </div>
            )}

            <div className="detail-page-inner-card ">
              <div>
                <CustomText
                  p
                  text={"Reward Mode:"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
              <div className="m-l-10">
                <CustomText
                  p
                  text={reward_data?.type?.toUpperCase()}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_primary"}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="detail-page-inner-card text-wrap m-b-12">
              <div>
                <CustomText
                  p
                  text={"Delivery Date:"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
              <div className="m-l-10">
                <CustomText
                  p
                  text={utils.convertUnixToDate(campaign_data.delivery_date)}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_primary"}
                  fontWeight={"500px"}
                />
              </div>
            </div>
            <div className="detail-page-inner-card">
              <div>
                <CustomText
                  p
                  text={"Reward Value:"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />
              </div>
              <div className="m-l-10">
                <CustomText
                  p
                  text={
                    reward_data.type == "cash"
                      ? campaign_data.max_budget
                      : reward_data.value
                  }
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_primary"}
                />
              </div>
            </div>
          </div>
        </div>
      </ViewCards>
    );
  }

  const cityId = !audience_data?.state ? "" : JSON.parse(audience_data?.state);

  let cityNames;
  if (cityId) {
    cityNames =
      audience_data?.state && cityId?.map((id) => utils.getLabelById(id));
  }
  function DeliverablesFun(platform_id) {
    return (
      selectedPlatform &&
      Object.values(selectedPlatform).map((items) => {
        return items.map((item) => {
          if (platform_id == item.platform_id) {
            return (
              <div className="deliverables-page-inner-card">
                <Icon
                  iconName={utils.socialMediaIcons(item.platform_id)}
                  width={24}
                  height={24}
                />
                <CustomText
                  p
                  ellipsis
                  fontSize={"font_size_large"}
                  textColor={"text_color_primary"}
                  text={utils.getContentTypeNameById(item.content_type_id)}
                />
              </div>
            );
          }
        });
      })
    );
  }

  let language =
    audience_data.language &&
    audience_data.language.map(utils.firstUpperCase).join(", ");

  function TargetInfluencerFun() {
    return (
      <ViewCards title={"Demographics"}>
        <div className="analytics-container-main">
          <div className="analytics-container">
            <>
              {influencer_data.categories && (
                <div className="detail-page-inner-card text-wrap">
                  <div>
                    <CustomText
                      p
                      text={"Categories:"}
                      fontSize={"font_size_large"}
                      textAlign={"text_align_left"}
                      textColor={"text_color_secondary"}
                    />
                  </div>
                  <div style={{ textWrap: "wrap" }} className="m-l-10">
                    <CustomText
                      p
                      text={
                        influencer_data.categories &&
                        Object.values(influencer_data.categories)
                          ?.map((item) => item.name)
                          .join(", ")
                      }
                      fontSize={"font_size_large"}
                      textAlign={"text_align_left"}
                      textColor={"text_color_primary"}
                    />
                  </div>
                </div>
              )}
            </>

            <>
              {audience_data.gender && (
                <div className="detail-page-inner-card text-wrap m-b-12">
                  <div>
                    <CustomText
                      p
                      text={"Gender:"}
                      fontSize={"font_size_large"}
                      textAlign={"text_align_left"}
                      textColor={"text_color_secondary"}
                    />
                  </div>
                  <div className="m-l-10">
                    <CustomText
                      p
                      text={audience_data.gender.toUpperCase()}
                      fontSize={"font_size_large"}
                      textAlign={"text_align_left"}
                      textColor={"text_color_primary"}
                      fontWeight={"500px"}
                    />
                  </div>
                </div>
              )}
            </>

            {/* {utils.isNull(audience_data.state) ? (
              ""
            ) : (
              <div className="detail-page-inner-card text-wrap m-b-12">
                <CustomText
                  p
                  text={"Locations:"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />

                <CustomText
                  p
                  text={audience_data.state}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_primary"}
                />
              </div>
            )} */}
          </div>
          <>
            {audience_data.language && (
              <div className="detail-page-inner-card m-b-12 ">
                <div>
                  <CustomText
                    p
                    text={"Languages: "}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
                <div className="m-l-10">
                  <CustomText
                    p
                    text={language}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_primary"}
                  />
                </div>
              </div>
            )}
          </>
          <div>
            {audience_data.age && (
              <div className="detail-page-inner-card m-b-12">
                <div>
                  <CustomText
                    p
                    text={"Age Group:"}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
                {audience_data.age &&
                  audience_data.age.map((item) => {
                    let ageData;
                    if (item == "18_25") {
                      ageData = "18-25,";
                    }
                    if (item == "26_35") {
                      ageData = "26-35,";
                    }
                    if (item == "36_45") {
                      ageData = "36-45,";
                    }
                    if (item == "46_55") {
                      ageData = "26-35,";
                    }
                    if (item == "56_65+") {
                      ageData = "56-65+";
                    }
                    return (
                      <div className="m-l-10">
                        <CustomText
                          p
                          text={ageData}
                          fontSize={"font_size_large"}
                          textAlign={"text_align_left"}
                          textColor={"text_color_primary"}
                          fontWeight={"500px"}
                        />
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <>
            {cityNames && cityNames?.length !== 0 && (
              <div className="detail-page-inner-card m-b-12 ">
                <div>
                  <CustomText
                    p
                    text={"Location: "}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_secondary"}
                  />
                </div>
                <div className="m-l-10 flex-row">
                  <div className="m-l-5 flex-row">
                    <CustomText
                      p
                      text={cityNames?.join(", ")}
                      fontSize={"font_size_large"}
                      textAlign={"text_align_left"}
                      textColor={"text_color_primary"}
                    />
                    {/* <div>
                            {" "}
                            {index !== audience_data?.state &&
                            JSON.parse(audience_data?.state).length - 1
                              ? ","
                              : ""}
                          </div> */}
                  </div>
                </div>
              </div>
            )}
          </>

          <div>
            {influencer_data.minimum_followers !== 0 && (
              <div className="detail-page-inner-card text-wrap">
                <CustomText
                  p
                  text={"Creator’s Level: "}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_color_secondary"}
                />

                <div className="m-l-10">
                  <CustomText
                    p
                    text={utils.getFollowersValue(
                      influencer_data.minimum_followers
                    )}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_primary"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </ViewCards>
    );
  }

  function SampleContent() {
    return (
      campaignDetail.sample_content_data &&
      campaignDetail.sample_content_data.map((item, index) => {
        if (item.sample_content_id.split(".").pop() == "mp4") {
          return (
            <div key={index} className="product-sample-main">
              <div
                id={index}
                onClick={() => handleVideoClick(index)}
                className="m-r-6"
              >
                {/* <Image
                  source={utils.generateCampaignSampleThumbUrl(
                    campaignDetail.campaign_data.brand_id,
                    item.sample_content_id
                  )}
                  width={106}
                  height={106}
                /> */}
                <video
                  preload="metadata"
                  style={{ height: 106, width: 106 }}
                  src={utils.generateCampaignSampleUrl(
                    campaignDetail.campaign_data.brand_id,
                    item.sample_content_id
                  )}
                />
              </div>

              {showVideo === index && (
                <div className="fullscreen-image">
                  <div style={{ position: "relative" }}>
                    <div
                      onClick={() => handleVideoClick(index)}
                      className="fullscreen-image-close-btn"
                    >
                      <Icon iconName="CloseRoundButtonIcon" />
                    </div>
                    <Video
                      source={utils.generateCampaignSampleUrl(
                        campaignDetail.campaign_data.brand_id,
                        item.sample_content_id
                      )}
                      width={600}
                      height={337}
                      type={"video/mp4"}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        } else {
          return (
            <>
              <div className="product-sample-main">
                <div onClick={() => handleClick(index)}>
                  <Image
                    source={utils.generateCampaignSampleUrl(
                      campaignDetail.campaign_data.brand_id,
                      item.sample_content_id
                    )}
                    width={106}
                    height={106}
                  />
                </div>

                {showImage === index && (
                  <div className="fullscreen-image">
                    <div style={{ position: "relative" }}>
                      <div
                        onClick={() => handleClick(index)}
                        className="fullscreen-image-close-btn"
                      >
                        <Icon iconName="CloseRoundButtonIcon" />
                      </div>
                      <Image
                        source={utils.generateCampaignSampleUrl(
                          campaignDetail.campaign_data.brand_id,
                          item.sample_content_id
                        )}
                        width={600}
                        height={337}
                      />
                    </div>
                  </div>
                )}
              </div>
            </>
          );
        }
      })
    );
  }

  const SeeMoreParagraph = ({ text, charLimit = 196 }) => {
    const toggleShowFull = () => {
      setShowFull(!showFull);
    };
    if (text != undefined) {
      return (
        <div className="instruction-text">
          {showFull ? text : text.slice(0, charLimit)}

          {text.length > charLimit && (
            <span className="see-more-text" onClick={toggleShowFull}>
              {showFull ? " See Less" : "... See More"}
            </span>
          )}
        </div>
      );
    }
  };
  return (
    <div style={{ minHeight: 500 }} className="campaign-detail-data-screen">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="left-side-detail">
            <div className="m-t-24">{CampaignDetailFun()}</div>
            <div className="m-t-24">
              <ViewCards title={"Deliverables"}>
                <div className="deliverables-card">
                  {selectedPlatform &&
                    Object.keys(selectedPlatform).map((item) => {
                      return <>{DeliverablesFun(item)}</>;
                    })}
                </div>
              </ViewCards>
            </div>
            <div style={{ position: "relative" }} className="m-t-24">
              {TargetInfluencerFun()}
              <div
                onClick={() => openDemographicsModal()}
                className="edit-campaign-icon-btn"
              >
                {campaignDetail?.campaign_data?.status !== "COMPLETED" && (
                  <Icon iconName="EditIconBlack" height={18} width={24} />
                )}
              </div>
            </div>
            <div className="m-t-24">
              {campaignDetail.sample_content_data &&
              campaignDetail.sample_content_data.length !== 0 ? (
                <div className="m-r-4 m-b-4">
                  <CustomText
                    p
                    textColor={"text_color_primary"}
                    fontSize={"font_size_medium"}
                    text={"Sample Content"}
                  />
                </div>
              ) : (
                ""
              )}

              <div className="m-t-12 flex-row">{SampleContent()}</div>
            </div>
          </div>
          <div className="right-side-detail">
            {/* <div className="m-t-24">{AnalyticsFun()}</div> */}
            <div className="m-t-24">{CollaborationRequirementFun()}</div>
            {campaign_data.url && (
              <div className="m-t-24">
                <ViewCards title={"URL"}>
                  <CustomText
                    p
                    text={campaign_data.url}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_color_primary"}
                    fontWeight={"500px"}
                  />
                </ViewCards>
              </div>
            )}

            <div className="m-t-24">
              {instruction_data?.instruction_note && (
                <ViewCards title={"Instructions for Creator"}>
                  <SeeMoreParagraph
                    text={ReactHtmlParser(instruction_data.instruction_note)}
                  />
                </ViewCards>
              )}
            </div>
          </div>
        </>
      )}
      {modalDemographicsIsOpen && (
        <EditDemographicsScreen
          campaign_data={campaign_data}
          audience_data={audience_data}
          influencer_data={influencer_data}
          campaignId={campaignId}
          closeModal={closeDemographicsModal}
          getCampaignUserDetail={getCampaignUserDetail}
        />
      )}
      {/* {modalCampaignDetailsIsOpen && (
        <EditCampaignDetailsScreen
          getCampaignUserDetail={getCampaignUserDetail}
        />
      )} */}
    </div>
  );
}

export default CampaignDetailsScreen;
