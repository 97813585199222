import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Core from "../common/clientSdk";
import * as Actions from "../redux/actions";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
// import toast, { Toaster } from "react-hot-toast";
import {
  Search,
  NotificationsIcon,
  ProfileDropDownIcon,
  FilterIcons,
} from "../components/assets/icons";
import { FallBack } from "../components/complex_component/index";

import { Image, CustomText, PrimaryCta } from "../components";
import Icon from "../components/core_component/icon";
import MyCreditsScreen from "./myCredits/myCreditsScreen";
import utils from "../common/utils";
import BrandListScreen from "./brandSetiing/brandListScreen";
import { useSnackbar } from "react-simple-snackbar";
import ReactGA from "react-ga";
import WebStorage from "../common/web_storage";
import { ORG_ID_KEY } from "../common/web_storage_keys";
import CompanySettingModal from "./companyDetails/addressDetailVerify";
const TRACKING_ID = "G-PTRN15HW4P";
ReactGA.initialize(TRACKING_ID);
const Header = (props) => {
  var navigate = useNavigate();
  var redux_dispatch = useDispatch();

  const [logout, setLogout] = useState([]);
  const [logoutDropDown, setLogoutDropDown] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [brandIsOpen, setBrandIsOpen] = useState(false);
  const [creditsDetail, setCreditsDetail] = useState("");
  const [orgData, setOrgData] = useState({});
  const [address, setAddress] = useState({});
  const [modalIsOpenAddress, setModalIsOpenAddress] = useState(false);
  const [openSnackbar] = useSnackbar();
  const dropdownRef = useRef(null);

  useEffect(() => {
    getOrgDataUser(updateProgress);
    getCreditDetail(updateProgress);
  }, []);

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  var navigate = useNavigate();

  function createCampaign() {
    // if (address && is_verified) {
    if (org_deep_data?.org_data?.credits < 10) {
      openModal();
      toast.info("Not enough credits for create campaign");
      return false;
    } else {
      navigate("/create-campaign");
    }
    // } else {
    //   navigate("/company-settings");
    //   openSnackbar("Profile not completed");
    // }
    googleAnalytics();
  }

  //data campaign call start

  var redux_selector = useSelector((state) => {
    return {
      Auth: state.BrandUserAuthReducer,
      UserData: state.BrandUserDataReducer,
    };
  });

  const {
    user_data,
    brand_deep_data,
    org_deep_data = {},
  } = redux_selector.UserData;

  const { image_id } = brand_deep_data["brand_data"];
  const brandImage = image_id
    ? utils.generateBrandLogoUrl(user_data.org_id, image_id)
    : require("../resources/images/fallback.png");
  function userLogout(progress_cb) {
    // var params = {
    //   user_id: redux_selector.BrandUser.user_data.user_id,
    // }
    Core.userLogout(userLogoutSuccess, userLogoutFailed, progress_cb);
  }

  function userLogoutSuccess(response_data) {
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_DATA,
      payload: { user_data: null },
    });

    // update brand data in redux
    redux_dispatch({
      type: Actions.UPDATE_BRAND_DATA,
      payload: { brand_deep_data: null },
    });

    redux_dispatch({
      type: Actions.UPDATE_ORG_DATA,
      payload: { org_deep_data: null },
    });

    // Update Auth token
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_AUTH,
      payload: { auth_token: null },
    });
    WebStorage.clearDataFromWebStorage(ORG_ID_KEY);

    navigate("/");
  }

  function userLogoutFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function getOrgDataUser(progress_cb) {
    const params = {
      org_id: brand_deep_data.brand_data.org_id,
    };
    Core.getOrgDataUser(
      getOrgDataUserSuccess,
      getOrgDataUserFailed,
      progress_cb,
      params
    );
  }

  function getOrgDataUserSuccess(response_data) {
    setOrgData(response_data.org_data.org_data);
    setAddress(response_data.org_data.address_data.address_premise);
    setLoading(false);
  }

  function getOrgDataUserFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function getCreditDetail(progress_cb) {
    var params = {};
    Core.getCreditDetail(
      getCreditDetailSuccess,
      getCreditDetailFailed,
      progress_cb,
      params
    );
  }

  function getCreditDetailSuccess(response_data) {
    setCreditsDetail(response_data?.credit_data);
    setLoading(false);
  }

  function getCreditDetailFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  // logout  end

  function toggleDropdown() {
    setIsDropdownOpen(!isDropdownOpen);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function googleAnalytics() {
    ReactGA.event({
      category: "ReelaxBrand",
      action: "REELAX_ADS_WEB_CREATE_CAMPAIGN_CLICK",
    });
  }

  function checkAddressPayment() {
    if (org_deep_data?.org_data.pan_number && address) {
      openModal();
    } else {
      openModalAddress();
    }
  }

  const openModal = () => {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const openModalAddress = () => {
    if (modalIsOpenAddress == false) {
      setModalIsOpenAddress(true);
    } else {
      setModalIsOpenAddress(false);
    }
  };

  const closeModalAddress = () => {
    setModalIsOpenAddress(false);
  };

  const brandModal = () => {
    if (brandIsOpen == false) {
      setBrandIsOpen(true);
    } else {
      setBrandIsOpen(false);
    }
  };

  const brandClose = () => {
    setBrandIsOpen(false);
  };

  const { is_kyc_done, is_verified } = orgData;

  return (
    <div className="header">
      <div className="header-left">
        <div
          onClick={() => navigate("/")}
          className="header-logo cursor-pointer"
        >
          <Image
            source={"../assets/images/reelax-new-logo.svg"}
            height="40px"
          />
        </div>
      </div>

      <div className="header-right">
        <div className="header-credit-container">
          {
            <div className="header-credit-left button1">
              {org_deep_data.org_data ? org_deep_data?.org_data.credits : 0}{" "}
              Credits
            </div>
          }

          <div
            onClick={() => checkAddressPayment()}
            className="header-credit-right button1"
          >
            Buy Credits
          </div>
        </div>
        <div className="create-campaign-button">
          <PrimaryCta
            third
            fontSize={"font_size_regular"}
            className="create-campaign"
            text={"Create Campaign"}
            onClick={() => createCampaign()}
          />
        </div>

        <div className="profile">
          <Image
            className="profile-pic"
            source={brandImage}
            onClick={brandModal}
          />

          <div className="profile-data" onClick={() => toggleDropdown()}>
            <div className="profile-content">
              <div className="flex-row">
                <div className="profile-name">
                  <CustomText
                    p
                    ellipsis
                    fontSize={"font_size_small "}
                    text={utils.truncateWithEllipsis(user_data.name, 32)}
                    textColor={"text_color_primary"}
                    fontWeight={"Poppins_regular_medium"}
                  />
                </div>
                <div className="p-l-15">
                  <ProfileDropDownIcon />
                </div>
              </div>
              <div className="profile-name">
                <CustomText
                  p
                  ellipsis
                  fontSize={"font_size_extraSmall"}
                  text={user_data.designation}
                  textColor={"text_color_primary"}
                  fontWeight={"Inter_regular"}
                />
              </div>
            </div>
          </div>
        </div>

        {isDropdownOpen && (
          <div className="logout-dropdown-content" ref={dropdownRef}>
            {/* <div className="dropdown-account-logout-detail">
              <CustomText
                p
                fontSize={"font_size_5xl "}
                text={"Account"}
                textColor={"text_color_light"}
                fontWeight={"rubik_regular"}
              />
              <CustomText
                p
                fontSize={"font_size_paragraph"}
                text={user_data.name}
                textColor={"text_color_light"}
                fontWeight={"rubik_regular"}
              />
            </div> */}
            <div>
              <PrimaryCta
                className="logout-main-btn"
                text="Log out"
                // isLoading={isLoading}
                onClick={() => userLogout(updateProgress)}
              />
            </div>
          </div>
        )}
        {/* <div className="notification">
          <NotificationsIcon />
        </div> */}
        {/* <div onClick={openModal} className="credit-modal-box-icon">
          <Icon iconName="bellIcon" />
        </div> */}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          zIndex={9999999}
        />

        {modalIsOpen && <MyCreditsScreen closeModal={closeModal} />}
        {brandIsOpen && <BrandListScreen closeModal={brandClose} />}
        {modalIsOpenAddress && (
          <CompanySettingModal closeModal={closeModalAddress} />
        )}
      </div>
    </div>
  );
};

export default Header;
