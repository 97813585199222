import React, { useState } from "react";
import {
  CustomText,
  Image,
  CustomTextInput,
  CustomTextArea,
  PrimaryCta,
  TeamMembers,
} from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useSelector } from "react-redux";
import Utils from "../../common/utils";
import Icon from "../../components/core_component/icon";
import Core from "../../common/clientSdk";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import ReportSidebar from "../report-sidebar";

function UserSettingsScreen() {
  const [isLoading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [error, set_error] = useState({ password: "" });
  const [openSnackbar] = useSnackbar();
  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const navigate = useNavigate();
  const { BrandUser = {} } = redux_selector;
  const { user_data = {} } = BrandUser;

  function postChangePassword(progress_cb) {
    const params = {
      old_password: oldPassword,
      new_password: newPassword,
    };
    if (validatePassword(newPassword) && validatePassword(oldPassword))
      Core.postChangePassword(
        postChangePasswordSuccess,
        postChangePasswordFailed,
        progress_cb,
        params
      );
  }

  function postChangePasswordSuccess(response_data) {
    setLoading(false);
  }

  function postChangePasswordFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function updateProgress(status) {}

  function validatePassword(value) {
    if (!validator.isLength(value, { min: 8 })) {
      set_error({
        ...error,
        password: "Required length 8 ",
      });
    } else {
      set_error({ ...error, password: "" });
      return true;
    }
  }

  return (
    <>
      <div className="dashboard-container">
        <SideMenu />

        <div className="main-screen">
          <Header />
          <div className="page-container">
            <div className="campaign-text-heading-user">
              <CustomText
                p
                text={"User Settings"}
                textColor={"text_color_primary"}
                fontSize={"font_size_2xl"}
              />
            </div>
            <div class="content-row-parent">
              <div
                onClick={() => navigate("/full-name")}
                className="content-row">
                <TeamMembers Name={"Full Name"} title={user_data.name} />
              </div>

              <div
                onClick={() => navigate("/phone-number")}
                className="content-row">
                <TeamMembers
                  Name={"Phone Number"}
                  title={user_data.phone_number}
                />
              </div>
              <div onClick={() => navigate("/email")} className="content-row">
                <TeamMembers Name={"Email"} title={user_data.email} />
              </div>
              <div
                onClick={() => navigate("/designation")}
                className="content-row">
                <TeamMembers
                  Name={"Designation"}
                  title={
                    user_data.designation
                      ? user_data.designation
                      : "Please Add Designation"
                  }
                />
              </div>
              <div
                onClick={() => navigate("/change-password")}
                className="content-row">
                <TeamMembers Name={"Change Password"} title={"********"} />
              </div>
            </div>
          </div>
          {/* <ReportSidebar /> */}
        </div>
      </div>
    </>
  );
}

export default UserSettingsScreen;
