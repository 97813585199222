'use-strict'


import { combineReducers, createStore } from 'redux';

import BrandUserDataReducer from './reducers/brandUser';
import BrandUserAuthReducer from './reducers/brandUserAuth';

const AppReducers = combineReducers({
  BrandUserDataReducer,
  BrandUserAuthReducer
});

const rootReducer = (state, action) => {
	return AppReducers(state,action);
}

let store = createStore(rootReducer);


export default store;
