import React from "react";
import { CustomTextInput, PrimaryCta, CustomText } from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useState, useEffect } from "react";
import Core from "../../common/clientSdk";
import validator from "validator";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "../loader";
import { useSnackbar } from "react-simple-snackbar";

function CompanyNameEdit() {
  const [loading, setLoading] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [orgData, setOrgData] = useState();
  const [openSnackbar] = useSnackbar();

  const [error, set_error] = useState({
    company_name: "",
    company_gst: "",
  });

  useEffect(() => {
    getOrgDataUser(updateProgress);
  }, []);

  useEffect(() => {
    setCompanyName(orgData?.name);
    setGstNumber(orgData?.gst_number);
  }, [orgData]);

  var navigate = useNavigate();
  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;
  const { user_data = {} } = BrandUser;

  const { brand_deep_data = {} } = BrandUser;
  const { brand_data = {} } = brand_deep_data;

  function getOrgDataUser(progress_cb) {
    const params = { org_id: brand_data.org_id };
    Core.getOrgDataUser(
      getOrgDataUserSuccess,
      getOrgDataUserFailed,
      progress_cb,
      params
    );
  }

  function getOrgDataUserSuccess(response_data) {
    setOrgData(response_data.org_data.org_data);
    setLoading(false);
  }

  function getOrgDataUserFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function updateProgress(status) {
    setLoading(status);
  }

  function postUpdateCompanyName(progress_cb) {
    const params = {
      org_id: brand_data.org_id,
      name: companyName,
      gst_number: gstNumber,
    };
    if (validateName(companyName) && validateGst(gstNumber))
      Core.postUpdateUserOrg(
        postUpdateCompanyNameSuccess,
        postUpdateCompanyNameFailed,
        progress_cb,
        params
      );
  }

  function postUpdateCompanyNameSuccess(response_data) {
    openSnackbar("Name update successful!");
    setLoading(false);
    navigate("/company-settings");
  }

  function postUpdateCompanyNameFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function validateName(value) {
    if (validator.isEmpty(value, {})) {
      set_error({ ...error, company_name: "Please enter name" });
    } else {
      set_error({ ...error, company_name: "" });
      return true;
    }
  }

  function validateGst(value) {
    if (validator.isEmpty(value, {})) {
      set_error({ ...error, company_gst: "Please enter gst number" });
    } else {
      set_error({ ...error, company_gst: "" });
      return true;
    }
  }

  return (
    <div className="dashboard-container">
      <SideMenu />

      <div className="main-screen">
        <Header />
        <div className="page-container">
          <div className="campaign-text-heading">
            <CustomText
              p
              text={"Company Details"}
              textColor={"text_color_primary"}
              fontSize={"font_size_2xl"}
            />
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="content-row-parent">
              {/* <div className="m-b-12 p-t-10">
                <CustomText
                  p
                  text={"Name"}
                  textColor={"text_color_light"}
                  fontSize={"font_size_xl"}
                />
              </div> */}

              <CustomTextInput
                className="custom-input-text"
                type="name"
                placeholder="Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                onBlur={(e) => validateName(e.target.value)}
              />
              <h5 className="error">{error.company_name}</h5>
              <CustomTextInput
                className="custom-input-text"
                type="text"
                placeholder="GST Number"
                value={gstNumber}
                onChange={(e) => setGstNumber(e.target.value)}
                onBlur={(e) => validateGst(e.target.value)}
              />
              <h5 className="error">{error.company_gst}</h5>

              <div className="">
                <PrimaryCta
                  fontSize={"font_size_large"}
                  fullWidth={true}
                  text={"Change"}
                  isLoading={loading}
                  onClick={() => {
                    postUpdateCompanyName(updateProgress);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default CompanyNameEdit;
