import React, { useRef, useState } from "react";
import {
  CustomText,
  Image,
  CustomTextInput,
  CustomTextArea,
  PrimaryCta,
} from "../../../components";
import SideMenu from "../../sideMenu";
import Header from "../../header";
import { useSelector } from "react-redux";
import validator from "validator";
import ImageUploading from "react-images-uploading";
import Icon from "../../../components/core_component/icon";
import Core from "../../../common/clientSdk";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import utils from "../../../common/utils";
import UploadImageS3 from "../../../common/s3";
import { Loader } from "../../loader";
import style from "../../../components/customTable/style";
import { useSnackbar } from "react-simple-snackbar";

function KycAddressProofScreen() {
  const imageRef = useRef(null);
  const [proofData, setProofData] = useState("");
  const [kycOrgData, setKycOrgData] = useState({});
  const [openSnackbar] = useSnackbar();
  const [selectedLocalImage, setSelectedLocalImage] = useState();
  const [imageId, setImageId] = useState();
  const [isUploading, setUploading] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, set_error] = useState({
    address_proof: "",
  });
  let navigate = useNavigate();

  const kycType = useParams().id;

  useEffect(() => {
    getOrgKycDetails(updateProgress);
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }
  function validateAddress(value) {
    if (validator.isEmpty(value, {})) {
      set_error({ ...error, address_proof: "Please enter Address Proof" });
    } else {
      set_error({ ...error, address_proof: "" });
      return true;
    }
  }

  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;
  const { user_data = {} } = BrandUser;

  const { brand_deep_data = {} } = BrandUser;
  const { brand_data = {} } = brand_deep_data;

  function getOrgKycDetails() {
    const params = { org_id: brand_data.org_id };
    Core.getOrgKycDetails(
      getOrgKycDetailsSuccess,
      getOrgKycDetailsFailed,
      updateProgress,
      params
    );
  }

  function getOrgKycDetailsSuccess(response_data) {
    setKycOrgData(response_data);
    setLoading(false);
  }

  function getOrgKycDetailsFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function postOrgUpdateKyc(progress_cb) {
    const params = {
      kyc_value: proofData,
      kyc_type: kycType,
      image_id: imageId,
      org_id: brand_data.org_id,
    };
    if (validateAddress(proofData))
      Core.postOrgUpdateKyc(
        postOrgUpdateKycSuccess,
        postOrgUpdateKycFailed,
        progress_cb,
        params
      );
  }

  function postOrgUpdateKycSuccess(response_data) {
    setLoading(false);
    openSnackbar("Your kyc has been sent for review");
    navigate("/company-settings");
  }

  function postOrgUpdateKycFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function generateUploadUrl(uploadedFileObject) {
    var params = {
      file_type: uploadedFileObject.type,
      action: "org_kyc_documents",
      id: brand_data.org_id,
    };
    setUploading(true);
    Core.getGenerateUploadUrl(
      generateUrlSuccess,
      generateUrlFailed,
      () => {},
      params,
      redux_selector.Auth.auth_token,
      uploadedFileObject
    );
  }

  function generateUrlSuccess(response, uploadedFileObject) {
    if (response) {
      // upload image file on server call uploadImageS3
      UploadImageS3(uploadedFileObject, response.upload_data, (error) => {
        setUploading(false);
        if (error) {
          return openSnackbar("Upload failed");
        }
        setImageId(response.upload_data.media_id);
      });
    } else {
      setUploading(false);
      openSnackbar("Something went wrong");
    }
  }

  function generateUrlFailed(error) {
    openSnackbar("Something went wrong");
    setUploading(false);
  }

  const handleImageClick = () => {
    imageRef.current.click();
  };

  const handleCropImage = (event) => {
    generateUploadUrl(event.target.files[0]);
    setSelectedLocalImage(event.target.files[0]);
  };

  // function renderKycItem() {
  //   if (kycOrgData && kycOrgData.kyc_data) {
  //     let kycData = kycOrgData.kyc_data.find((item) => {
  //       return item.kyc_type == kycType;
  //     });

  //     // if (kycData?.status === "REJECTED") {
  //     //   kycData = null;
  //     // }
  //     function StatusColor() {
  //       if (kycData?.status == "IN_REVIEW") {
  //         return (
  //           <CustomText
  //             p
  //             text={kycData?.status}
  //             textColor={"text_active_color"}
  //             fontSize={"font_size_regular"}
  //             textAlign={"text_align_right"}
  //           />
  //         );
  //       }
  //       if (kycData?.status === "REJECTED") {
  //         return (
  //           <div className="rejected-kyc-comment">
  //             <CustomText
  //               p
  //               text={` Rejection reason : ${kycData.comment}`}
  //               textColor={"text_pending_color"}
  //               fontSize={"font_size_regular"}
  //             />
  //             <CustomText
  //               p
  //               text={kycData?.status}
  //               textColor={"text_pending_color"}
  //               fontSize={"font_size_regular"}
  //             />
  //           </div>
  //         );
  //       }
  //       if (kycData?.status === "COMPLETED") {
  //         return (
  //           <CustomText
  //             p
  //             text={kycData?.status}
  //             textColor={"text_completed_color"}
  //             fontSize={"font_size_regular"}
  //             textAlign={"text_align_right"}
  //           />
  //         );
  //       }
  //     }

  //     return (
  //       <div className="setting-screen-main-container">
  //         <div className="kyc-heading-and-status m-b-12 p-t-10">
  //           <CustomText
  //             p
  //             text={utils.getKYCTypeString(kycType)["n"]}
  //             textColor={"text_color_light"}
  //             fontSize={"font_size_xl"}
  //           />
  //           {StatusColor()}
  //         </div>
  //         <div className="m-b-12 p-t-10">
  //           <CustomText
  //             p
  //             text={utils.getKYCTypeString(kycType)["d"]}
  //             textColor={"text_color_light"}
  //             fontSize={"font_size_regular"}
  //           />
  //         </div>

  //         <div className="m-t-15 full-width">
  //           <CustomTextInput
  //             disabled={kycData?.status === "COMPLETED"}
  //             placeholder={utils.getKYCTypeString(kycType)["n"]}
  //             value={
  //               !(kycData?.status === "REJECTED") &&
  //               !(kycData?.status === "PENDING")
  //                 ? kycData?.kyc_value
  //                 : proofData
  //             }
  //             onChange={(e) => setProofData(e.target.value)}
  //             onBlur={(e) => validateAddress(e.target.value)}
  //           />
  //           <h5 className="error">{error.address_proof}</h5>
  //         </div>
  //         {!(kycData?.status === "REJECTED") &&
  //         !(kycData?.status === "PENDING") ? (
  //           <div className="m-t-16">
  //             <Image
  //               className="kyc-uploaded-id"
  //               source={utils.generateKycImageUrl(
  //                 brand_data.org_id,
  //                 kycData.image_id
  //               )}
  //             />
  //           </div>
  //         ) : (
  //           <div style={{ position: "relative" }}>
  //             {" "}
  //             <div
  //               style={{
  //                 //display: 'flex',
  //                 width: "370px",
  //                 height: "208px",
  //                 backgroundColor: "#FFFF",
  //                 border: "1px solid black",
  //                 borderRadius: "10px",
  //                 marginRight: "8px",
  //                 marginTop: "16px",
  //                 cursor: "pointer",
  //                 display: "flex",
  //                 alignItems: "center",
  //                 justifyContent: "center",
  //               }}
  //               className="kyc-uploaded-id"
  //               onClick={handleImageClick}
  //             >
  //               {/* <div style={{}}>
  //               <Icon iconName="UploadIconFile" height={20} width={20} />
  //             </div> */}
  //               {selectedLocalImage ? (
  //                 <div>
  //                   <img
  //                     alt="not fount"
  //                     width={"225px"}
  //                     height={"150px"}
  //                     // style={{ borderRadius: "50%" }}
  //                     src={URL.createObjectURL(selectedLocalImage)}
  //                   />
  //                 </div>
  //               ) : (
  //                 <div>
  //                   <Icon iconName="UploadIconFile" height={20} width={20} />
  //                 </div>
  //               )}

  //               <input
  //                 style={{ display: "none" }}
  //                 type="file"
  //                 name="myImage"
  //                 ref={imageRef}
  //                 onChange={handleCropImage}
  //               />
  //             </div>
  //             <div className="kyc-page-loader">{isUploading && <Loader />}</div>
  //           </div>
  //         )}
  //         {!(kycData?.status === "REJECTED") && (
  //           <div className="m-t-15 full-width">
  //             <PrimaryCta
  //               fontSize={"font_size_large"}
  //               fullWidth={true}
  //               text={"Save"}
  //               isLoading={loading}
  //               onClick={() => postOrgUpdateKyc(updateProgress)}
  //               disabled={utils.isUndefined(imageId) || utils.isUndefined(null)}
  //             />
  //           </div>
  //         )}
  //       </div>
  //     );
  //   }
  // }

  function renderKycItem() {
    if (kycOrgData && kycOrgData.kyc_data) {
      let kycData = kycOrgData.kyc_data.find((item) => {
        return item.kyc_type == kycType;
      });
      let comment = "";
      let kycReject = "";
      if (kycData?.status === "REJECTED") {
        comment = kycData.comment;
        kycReject = kycData?.status;

        kycData = null;
      }

      function StatusColor() {
        if (kycData?.status == "IN_REVIEW") {
          return (
            <CustomText
              p
              text={kycData?.status}
              textColor={"text_active_color"}
              fontSize={"font_size_regular"}
            />
          );
        }
        if (kycReject === "REJECTED") {
          return (
            <div className="rejected-kyc-comment">
              <CustomText
                p
                text={` Rejection reason : ${comment}`}
                textColor={"text_pending_color"}
                fontSize={"font_size_regular"}
              />
              <CustomText
                p
                text={kycReject}
                textColor={"text_pending_color"}
                fontSize={"font_size_regular"}
              />
            </div>
          );
        }
        if (kycData?.status === "COMPLETED") {
          return (
            <CustomText
              p
              text={kycData?.status}
              textColor={"text_completed_color"}
              fontSize={"font_size_regular"}
            />
          );
        }
      }

      return (
        <div className="setting-screen-main-container">
          <div className="kyc-heading-and-status m-b-12 p-t-10">
            <CustomText
              p
              text={utils.getKYCTypeString(kycType)["n"]}
              textColor={"text_color_primary"}
              fontSize={"font_size_xl"}
            />
            {StatusColor()}
          </div>
          <div className="m-b-12 p-t-10">
            <CustomText
              p
              text={utils.getKYCTypeString(kycType)["d"]}
              textColor={"text_color_primary"}
              fontSize={"font_size_regular"}
            />
          </div>

          <div className="m-t-15 full-width">
            <CustomTextInput
              className="custom-input-text"
              disabled={kycData}
              placeholder={utils.getKYCTypeString(kycType)["n"]}
              value={kycData ? kycData.kyc_value : proofData}
              onChange={(e) => setProofData(e.target.value)}
              onBlur={(e) => validateAddress(e.target.value)}
            />
            <h5 className="error">{error.address_proof}</h5>
          </div>
          {kycData ? (
            <div className="m-t-16">
              <Image
                className="kyc-uploaded-id"
                source={utils.generateKycImageUrl(
                  brand_data.org_id,
                  kycData.image_id
                )}
              />
            </div>
          ) : (
            <div style={{ position: "relative" }}>
              {" "}
              <div
                style={{
                  //display: 'flex',
                  width: "370px",
                  height: "208px",
                  backgroundColor: "#FFFF",
                  border: "1px solid black",
                  borderRadius: "10px",
                  marginRight: "8px",
                  marginTop: "16px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="kyc-uploaded-id"
                onClick={handleImageClick}
              >
                {/* <div style={{}}>
                <Icon iconName="UploadIconFile" height={20} width={20} />
              </div> */}
                {selectedLocalImage ? (
                  <div>
                    <img
                      alt="not fount"
                      width={"225px"}
                      height={"150px"}
                      // style={{ borderRadius: "50%" }}
                      src={URL.createObjectURL(selectedLocalImage)}
                    />
                  </div>
                ) : (
                  <div>
                    <Icon iconName="UploadIconFile" height={20} width={20} />
                  </div>
                )}

                <input
                  style={{ display: "none" }}
                  type="file"
                  name="myImage"
                  ref={imageRef}
                  onChange={handleCropImage}
                />
              </div>
              <div className="kyc-page-loader">{isUploading && <Loader />}</div>
            </div>
          )}
          {!kycData && (
            <div className="m-t-15 full-width">
              <PrimaryCta
                fontSize={"font_size_large"}
                fullWidth={true}
                text={"Save"}
                isLoading={loading}
                onClick={() => postOrgUpdateKyc(updateProgress)}
                disabled={utils.isUndefined(imageId) || utils.isUndefined(null)}
              />
            </div>
          )}
        </div>
      );
    }
  }

  return (
    <div className="dashboard-container">
      <SideMenu />

      <div className="main-screen">
        <Header />
        <div className="page-container">
          <div className="campaign-text-heading">
            <CustomText
              p
              text={"Kyc"}
              textColor={"text_color_light"}
              fontSize={"font_size_2xl"}
            />
          </div>
          {loading ? <Loader /> : <>{renderKycItem()}</>}
        </div>
      </div>
    </div>
  );
}

export default KycAddressProofScreen;
