import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "../sideMenu";
import Header from "../header";
import { CustomText, CustomTextInput, PrimaryCta } from "../../components";
import { useState } from "react";
import Core from "../../common/clientSdk";
import validator from "validator";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import * as Actions from "../../redux/actions";
function UserEmail() {
  const [isLoading, setLoading] = useState(false);
  const [changeEmail, setChangeEmail] = useState("");
  const [openStatus, setOpenStatus] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [error, set_error] = useState({
    email: "",
  });

  const navigate = useNavigate();
  const redux_dispatch = useDispatch();
  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;

  const { user_data = {} } = BrandUser;

  useEffect(() => {
    setChangeEmail(user_data.email);
  }, []);
  function postUpdateUserProfile(progress_cb) {
    const params = {
      user_data: JSON.stringify({
        org_id: user_data.org_id,
        user_id: user_data.user_id,
        email: changeEmail,
      }),
    };
    if (validateEmail(changeEmail))
      Core.postUpdateUserProfile(
        postUpdateUserProfileSuccess,
        postUpdateUserProfileFailed,
        progress_cb,
        params
      );
  }

  function postUpdateUserProfileSuccess(response_data) {
    setLoading(false);
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_DATA,
      payload: { user_data: response_data.user_data },
    });
    openSnackbar("User email changed successfully!");
    navigate("/user-settings");
  }

  function postUpdateUserProfileFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function updateProgress(status) {
    setLoading(status);
  }

  function validateEmail(value) {
    if (!validator.isEmail(value, {})) {
      set_error({ ...error, email: "Please enter valid email" });
    } else {
      set_error({ ...error, email: "" });
      return true;
    }
  }
  return (
    <div className="dashboard-container">
      <SideMenu />

      <div className="main-screen">
        <Header />
        <div className="page-container">
          <div className="campaign-text-heading">
            <CustomText
              p
              text={"Email"}
              textColor={"text_color_light"}
              fontSize={"font_size_2xl"}
            />
          </div>

          <div className="content-row-parent flex-row">
            <div className="full-width">
              <CustomTextInput
                type="email"
                value={changeEmail}
                onChange={(e) => setChangeEmail(e.target.value)}
                onBlur={(e) => validateEmail(e.target.value)}
              />
              <h5 className="error">{error.email}</h5>
            </div>
            <div className="m-l-15">
              <PrimaryCta
                fontSize={"font_size_large"}
                fullWidth={true}
                text={"Save"}
                isLoading={isLoading}
                onClick={() => {
                  postUpdateUserProfile(updateProgress);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserEmail;
