import React from "react";
import {
  CustomText,
  FallBack,
  CustomTextInput,
  PrimaryCta,
} from "../../components";

import { useState, useEffect } from "react";
import Core from "../../common/clientSdk";
import Modal from "react-modal";
import { Loader } from "../loader";
import Icon from "../../components/core_component/icon";
import Utils from "../../common/utils";
import { ToastContainer, toast } from "react-toastify";

const customStylesModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var(--gray-gray-50)",
    textAlign: "center",
    zIndex: "5",
    minHeight: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    paddingLeft: 30,
    width: "368px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};

function CreateCollectionModal({ closeModal, getCollectionList }) {
  const [collectionName, setCollectionName] = useState("");
  const [loading, setLoading] = useState(false);

  function createCollectionList() {
    const params = {
      collection_name: collectionName,
    };
    Core.postAddCollection(
      createCollectionSuccess,
      createCollectionListFailed,
      (status) => setLoading(status),
      params
    );
  }

  function createCollectionSuccess(response_data) {
    getCollectionList();
    closeModal();
    toast.success("Create Collection Successfully");
  }

  function createCollectionListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }
  return (
    <Modal isOpen={true} onRequestClose={closeModal} style={customStylesModal}>
      <div onClick={closeModal} className="close-btn-icon">
        <Icon iconName="CloseRoundButtonIcon" />
      </div>
      <div className="">
        <CustomText
          h5
          text={"Create a new collection"}
          textColor={"text_grey_400_color"}
          className="m-b-20 typography-centerH7"
        />
      </div>
      <div className="m-t-10">
        <div style={{ textAlign: "center" }} className="flex-row">
          <CustomText
            h5
            fontSize={"font_size_large"}
            text={"Collection Name"}
            textColor={"text_grey_400_color"}
            fontWeight={"Poppins_regular_large"}
            textAlign={"text_align_left"}
            className="m-b-8 m-l-10"
          />
        </div>
        <CustomTextInput
          invert
          className={"input-height-counter"}
          value={collectionName}
          onChange={(e) => setCollectionName(e.target.value)}
          placeHolder={"collection name"}
          type="text"
        />
      </div>

      <div className="m-t-25">
        <PrimaryCta
          fifth
          onClick={() => createCollectionList()}
          fontSize={"font_size_large"}
          className="collection-create-btn-2"
          text={"Create"}
          isLoading={loading}
          // disabled={props.disabled}
        />

        {/* <PrimaryCta
          invert
          onClick={props.onClose}
          className="confirm-btn-bid"
          fontSize={"font_size_large"}
          text={"Cancel"}
          disabled={props.disabled}
        /> */}
        <button
          style={{ height: 44 }}
          className="confirm-btn-bid  m-l-10"
          onClick={closeModal}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}

export default CreateCollectionModal;
