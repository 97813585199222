module.exports = {
  FASHION_BEAUTY: {
    campaign_data: {
      campaign_data: {
        campaign_id: "qstbdj",
        seen_count: 0,
        user_id: "pan3og",
        brand_id: "1v0xer",
        name: "Create a Reel for ",
        type: "social_media",
        url: "",
        content_types:
          '[{"platform_id": "btll", "platform_name": "Instagram", "content_type_id": "o5oi"}]',
        max_budget: 1500,
        number: 50,
        engagement_type: "AUCTION",
        end_date: 1715193000,
        delivery_date: 1716489000,
        status: "IN_REVIEW",
        assignment_count: 0,
        bid_count: 0,
        date_added: 1713943002,
        date_modified: 1713943002,
      },
      content_type_deep_data: [
        {
          content_data: {
            content_type_id: "o5oi",
            platform_id: "btll",
          },
          platform_data: {
            platform_id: "btll",
          },
        },
      ],
      reward_data: {
        type: "cash",
        value: null,
        reward_by_follower: null,
      },
      audience_data: {
        age: ["18_25", "26_35", "36_45", "46_55", "56_65+"],
        gender: "all",
        city: null,
        state: null,
        country: ["in"],
        language: ["hindi", "english"],
      },
      influencer_data: {
        age: "null_null",
        gender: null,
        state: null,
        language: null,
        categories: {
          m0im: {
            id: "m0im",
            campaign_id: "qstbdj",
            name: "Fashion & Beauty",
          },
        },
        maximum_followers: null,
        minimum_followers: 1000,
      },
      instruction_data: {
        instruction_note:
          "<ol>\n  <li>&nbsp;Create an Instagram Reel of good quality for&nbsp;</li>\n  <li>Add the sample video in your instagram story and upload the screenshot, make sure you don't remove the story otherwise payout will not be generated.&nbsp;</li>\n  <li>Create a good quality content for brand make sure audio and video quality is good. &nbsp;</li>\n</ol>",
      },
      brand_data: {
        brand_id: "1v0xer",
        org_id: "rbvyhn",
        name: "hhhhh",
        note: "jjjjjjjjj",
        image_id: "660p5u0ueuldvbfowm8hvw4th.jpg",
        date_added: 1713943002,
        date_modified: 1713943002,
      },
      sample_content_data: [],
    },
  },
  FASHION_BEAUTY_STORY: {
    campaign_data: {
      campaign_data: {
        campaign_id: "fc5t4a",
        seen_count: 0,
        user_id: "pan3og",
        brand_id: "1v0xer",
        name: "Add Story of",
        type: "social_media",
        url: "",
        content_types:
          '[{"platform_id": null, "platform_name": null, "content_type_id": "uyts"}]',
        max_budget: 500,
        number: 50,
        engagement_type: "AUCTION",
        end_date: 1715193000,
        delivery_date: 1716489000,
        status: "IN_REVIEW",
        assignment_count: 0,
        bid_count: 0,
        date_added: 1713944626,
        date_modified: 1713944626,
      },
      content_type_deep_data: [
        {
          content_data: {
            content_type_id: "uyts",
          },
          platform_data: {
            platform_id: null,
          },
        },
      ],
      reward_data: {
        type: "cash",
        value: null,
        reward_by_follower: null,
      },
      audience_data: {
        age: ["18_25", "26_35", "36_45", "46_55", "56_65+"],
        gender: "all",
        city: null,
        state: null,
        country: ["in"],
        language: ["hindi", "english"],
      },
      influencer_data: {
        age: "null_null",
        gender: null,
        state: null,
        language: null,
        categories: {
          m0im: {
            id: "m0im",
            campaign_id: "fc5t4a",
            name: "Fashion & Beauty",
          },
        },
        maximum_followers: null,
        minimum_followers: 1000,
      },
      instruction_data: {
        instruction_note:
          "<ol>\n  <li>Create an Instagram Reel of good quality &nbsp;</li>\n  <li>Add the sample video in your instagram story and upload the screenshot, make sure you don't remove the story otherwise payout will not be generated&nbsp;</li>\n  <li>Create a good quality content for brand make sure audio and video quality is good. &nbsp;</li>\n</ol>",
      },
      brand_data: {
        brand_id: "1v0xer",
        org_id: "rbvyhn",
        name: "hhhhh",
        note: "jjjjjjjjj",
        image_id: "660p5u0ueuldvbfowm8hvw4th.jpg",
        date_added: 1713944626,
        date_modified: 1713944626,
      },
      sample_content_data: [],
    },
  },
  FASHION_BEAUTY_CONTENT: {
    campaign_data: {
      campaign_data: {
        campaign_id: "j5cm74",
        seen_count: 0,
        user_id: "pan3og",
        brand_id: "1v0xer",
        name: "Create UGC video for ",
        type: "content",
        url: "",
        content_types:
          '[{"platform_id": "btll", "platform_name": "Instagram", "content_type_id": "o5oi"}]',
        max_budget: 1500,
        number: 20,
        engagement_type: "AUCTION",
        end_date: 1715193000,
        delivery_date: 1716489000,
        status: "IN_REVIEW",
        assignment_count: 0,
        bid_count: 0,
        date_added: 1713947511,
        date_modified: 1713947511,
      },
      content_type_deep_data: [
        {
          content_data: {
            content_type_id: "o5oi",
            platform_id: "btll",
          },
          platform_data: {
            platform_id: "btll",
          },
        },
      ],
      reward_data: {
        type: "cash",
        value: null,
        reward_by_follower: null,
      },
      audience_data: {
        age: ["18_25", "26_35", "36_45", "46_55", "56_65+"],
        gender: "all",
        city: null,
        state: null,
        country: ["in"],
        language: ["hindi", "english"],
      },
      influencer_data: {
        age: "null_null",
        gender: null,
        state: null,
        language: null,
        categories: {
          m0im: {
            id: "m0im",
            campaign_id: "j5cm74",
            name: "Fashion & Beauty",
          },
        },
        maximum_followers: null,
        minimum_followers: 0,
      },
      instruction_data: {
        instruction_note:
          "<ol>\n  <li>&nbsp;Create an Instagram Reel of good quality &nbsp;</li>\n  <li>&nbsp;&nbsp;Add the sample video in your instagram story and upload the screenshot, make sure you don't remove the story otherwise payout will not be generated. &nbsp;</li>\n  <li>&nbsp;Create a good quality content for brand make sure audio and video quality is good.&nbsp;</li>\n</ol>",
      },
      brand_data: {
        brand_id: "1v0xer",
        org_id: "rbvyhn",
        name: "hhhhh",
        note: "jjjjjjjjj",
        image_id: "660p5u0ueuldvbfowm8hvw4th.jpg",
        date_added: 1713947511,
        date_modified: 1713947511,
      },
      sample_content_data: [],
    },
  },
  FASHION_BEAUTY_MICRO: {
    campaign_data: {
      campaign_data: {
        campaign_id: "t4i7oy",
        seen_count: 0,
        user_id: "pan3og",
        brand_id: "1v0xer",
        name: "Create a Reel for",
        type: "social_media",
        url: "",
        content_types:
          '[{"platform_id": "btll", "platform_name": "Instagram", "content_type_id": "o5oi"}]',
        max_budget: 5000,
        number: 10,
        engagement_type: "AUCTION",
        end_date: 1715193000,
        delivery_date: 1716489000,
        status: "IN_REVIEW",
        assignment_count: 0,
        bid_count: 0,
        date_added: 1713947779,
        date_modified: 1713947779,
      },
      content_type_deep_data: [
        {
          content_data: {
            content_type_id: "o5oi",
            platform_id: "btll",
          },
          platform_data: {
            platform_id: "btll",
          },
        },
      ],
      reward_data: {
        type: "cash",
        value: null,
        reward_by_follower: null,
      },
      audience_data: {
        age: ["18_25", "26_35", "36_45", "46_55", "56_65+"],
        gender: "all",
        city: null,
        state: null,
        country: ["in"],
        language: ["hindi", "english"],
      },
      influencer_data: {
        age: "null_null",
        gender: null,
        state: null,
        language: null,
        categories: {
          m0im: {
            id: "m0im",
            campaign_id: "t4i7oy",
            name: "Fashion & Beauty",
          },
        },
        maximum_followers: null,
        minimum_followers: 10000,
      },
      instruction_data: {
        instruction_note:
          "<p>&nbsp;Create an Instagram Reel of good quality &nbsp;</p>",
      },
      brand_data: {
        brand_id: "1v0xer",
        org_id: "rbvyhn",
        name: "hhhhh",
        note: "jjjjjjjjj",
        image_id: "660p5u0ueuldvbfowm8hvw4th.jpg",
        date_added: 1713947779,
        date_modified: 1713947779,
      },
      sample_content_data: [],
    },
  },
  TECHNOLOGY_MICRO: {
    campaign_data: {
      campaign_data: {
        campaign_id: "1b9nxt",
        seen_count: 0,
        user_id: "pan3og",
        brand_id: "1v0xer",
        name: "Create a Reel for ",
        type: "social_media",
        url: "",
        content_types:
          '[{"platform_id": "btll", "platform_name": "Instagram", "content_type_id": "o5oi"}]',
        max_budget: 8000,
        number: 5,
        engagement_type: "AUCTION",
        end_date: 1715193000,
        delivery_date: 1716489000,
        status: "IN_REVIEW",
        assignment_count: 0,
        bid_count: 0,
        date_added: 1713951688,
        date_modified: 1713951688,
      },
      content_type_deep_data: [
        {
          content_data: {
            content_type_id: "o5oi",
            platform_id: "btll",
          },
          platform_data: {
            platform_id: "btll",
          },
        },
      ],
      reward_data: {
        type: "cash",
        value: null,
        reward_by_follower: null,
      },
      audience_data: {
        age: ["18_25", "26_35", "36_45", "46_55", "56_65+"],
        gender: "all",
        city: null,
        state: null,
        country: ["in"],
        language: ["hindi", "english"],
      },
      influencer_data: {
        age: "null_null",
        gender: null,
        state: null,
        language: null,
        categories: {
          w7s7: {
            id: "w7s7",
            campaign_id: "1b9nxt",
            name: "Technology",
          },
        },
        maximum_followers: null,
        minimum_followers: 10000,
      },
      instruction_data: {
        instruction_note:
          "<p>&nbsp;Create an Instagram Reel of good quality &nbsp;</p>",
      },
      brand_data: {
        brand_id: "1v0xer",
        org_id: "rbvyhn",
        name: "hhhhh",
        note: "jjjjjjjjj",
        image_id: "660p5u0ueuldvbfowm8hvw4th.jpg",
        date_added: 1713951688,
        date_modified: 1713951688,
      },
      sample_content_data: [],
    },
  },
  FOOD_DRINK_MICRO: {
    campaign_data: {
      campaign_data: {
        campaign_id: "u8ytry",
        seen_count: 0,
        user_id: "pan3og",
        brand_id: "1v0xer",
        name: "Create a Reel for ",
        type: "social_media",
        url: "",
        content_types:
          '[{"platform_id": "btll", "platform_name": "Instagram", "content_type_id": "o5oi"}]',
        max_budget: 10000,
        number: 5,
        engagement_type: "AUCTION",
        end_date: 1715193000,
        delivery_date: 1716489000,
        status: "IN_REVIEW",
        assignment_count: 0,
        bid_count: 0,
        date_added: 1713952028,
        date_modified: 1713952028,
      },
      content_type_deep_data: [
        {
          content_data: {
            content_type_id: "o5oi",
            platform_id: "btll",
          },
          platform_data: {
            platform_id: "btll",
          },
        },
      ],
      reward_data: {
        type: "cash",
        value: null,
        reward_by_follower: null,
      },
      audience_data: {
        age: ["18_25", "26_35", "36_45", "46_55", "56_65+"],
        gender: "all",
        city: null,
        state: null,
        country: ["in"],
        language: ["hindi", "english"],
      },
      influencer_data: {
        age: "null_null",
        gender: null,
        state: null,
        language: null,
        categories: {
          nsld: {
            id: "nsld",
            campaign_id: "u8ytry",
            name: "Food & Drink",
          },
        },
        maximum_followers: null,
        minimum_followers: 10000,
      },
      instruction_data: {
        instruction_note:
          "<p>&nbsp;Create an Instagram Reel of good quality &nbsp;</p>",
      },
      brand_data: {
        brand_id: "1v0xer",
        org_id: "rbvyhn",
        name: "hhhhh",
        note: "jjjjjjjjj",
        image_id: "660p5u0ueuldvbfowm8hvw4th.jpg",
        date_added: 1713952028,
        date_modified: 1713952028,
      },
      sample_content_data: [],
    },
  },
};
