import React, { useEffect, useState } from "react";
import {
  CustomCheckBoxCircular,
  CustomText,
  Image,
  PrimaryCta,
} from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import SideMenu from "../../screens/sideMenu";
import Header from "../../screens/header";
import "react-best-tabs/dist/index.css";

import CampaignDetailsScreen from "./campaignDetailsScreen";
import BidListScreen from "./bidListScreen";
import AssignmentListScreen from "./assignmentListScreen";
import EditCampaignDetailsScreen from "../editCampaign/editCampaignDetailsScreen";
import { useSelector } from "react-redux";
import Core from "../../common/clientSdk";
import Toggle from "react-toggle";
import { Loader } from "../loader";
import InviteList from "./inviteList";
import ContentList from "./contentList";
import AnalyticsCampaign from "./analyticsCampaign";

function CampaignDetailsParentScreen(props) {
  const [campaignDetail, setCampaignDetail] = useState({});

  const [activeTabPage, setActiveTabPage] = useState("");
  const [activeTab, setActiveTab] = useState("proposal-page");

  const [published, setPublished] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [modalCampaignDetailsIsOpen, setModalCampaignDetailsIsOpen] =
    useState(false);
  const campaignId = useParams().id;

  useEffect(() => {
    getCampaignUserDetail();
  }, []);

  // useEffect(() => {
  //   setPublished(true);
  //   if (campaignDetail?.campaign_data?.status == "ACTIVE") {
  //     setPublished(true);
  //   }
  //   if (campaignDetail?.campaign_data?.status == "INACTIVE") {
  //     setPublished(false);
  //   }
  // }, [campaignDetail?.campaign_data?.status]);

  function updateProgress(status) {
    setLoading(status);
  }

  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { brand_data = {} } = brand_deep_data;

  function getCampaignUserDetail() {
    const params = {
      brand_id: brand_data.brand_id,
      campaign_id: campaignId,
    };
    Core.getCampaignUserDetail(
      getCampaignUserDetailSuccess,
      getCampaignUserDetailFailed,
      updateProgress,
      params
    );
  }

  function getCampaignUserDetailSuccess(response_data) {
    setCampaignDetail(response_data.campaign_data);
    setLoading(false);
  }

  function getCampaignUserDetailFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function postPublishedCampaign() {
    const params = {
      brand_id: brand_data.brand_id,
      campaign_id: campaignId,
    };
    Core.postPublishedCampaign(
      postPublishedCampaignSuccess,
      postPublishedCampaignFailed,
      updateProgress,
      params
    );
  }

  function postPublishedCampaignSuccess(response_data) {
    getCampaignUserDetail();
  }

  function postPublishedCampaignFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function postUnPublishedCampaign() {
    const params = {
      brand_id: brand_data.brand_id,
      campaign_id: campaignId,
    };
    Core.postUnPublishedCampaign(
      postUnPublishedCampaignSuccess,
      postUnPublishedCampaignFailed,
      updateProgress,
      params
    );
  }

  function postUnPublishedCampaignSuccess(response_data) {
    getCampaignUserDetail();
  }

  function postUnPublishedCampaignFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const openCampaignDetailsModal = () => {
    if (modalCampaignDetailsIsOpen == false) {
      setModalCampaignDetailsIsOpen(true);
    } else {
      setModalCampaignDetailsIsOpen(false);
    }
  };

  const closeCampaignDetailsModal = () => {
    setModalCampaignDetailsIsOpen(false);
  };

  function PublishedAndUnpublished() {
    if (campaignDetail?.campaign_data?.status !== "ACTIVE") {
      postPublishedCampaign();
    } else {
      postUnPublishedCampaign();
    }
    getCampaignUserDetail();
  }
  console.log(
    "campaignDetail?.campaign_data",
    campaignDetail?.campaign_data?.type
  );
  return (
    <div>
      <div className="dashboard-container">
        <SideMenu />
        <div className="main-screen">
          <Header title="Campaign Details" />

          <div className="internal-page-container">
            {isLoading ? (
              <div className="loader-mid">
                <Loader />
              </div>
            ) : (
              <>
                <div className="campaign-text-heading">
                  <CustomText
                    h5
                    text={"Campaign Details"}
                    className="typographyH7"
                  />
                </div>
                <div
                  className={
                    activeTab === "proposal-page"
                      ? "proposal-detail-container"
                      : "campaign-detail-container"
                  }
                >
                  <div className="campaign-detail-upper-container">
                    <div className="campaign-detail-tab-header">
                      <div
                        className={activeTab === "detail-page" ? "active" : ""}
                        onClick={() => handleTabClick("detail-page")}
                      >
                        <div className="campaign-detail-btn-tabs">
                          <CustomText
                            p
                            // textColor={"text_color_primary"}
                            fontSize={"font_size_extra_large"}
                            text={"Details"}
                          />
                        </div>
                      </div>

                      <div
                        className={activeTab === "invite-page" ? "active" : ""}
                        onClick={() => handleTabClick("invite-page")}
                      >
                        <div className="campaign-detail-btn-tabs">
                          <CustomText
                            p
                            // textColor={"text_color_primary"}
                            fontSize={"font_size_extra_large"}
                            text={"Invite"}
                          />
                        </div>
                      </div>

                      <div
                        className={
                          activeTab === "proposal-page" ? "active" : ""
                        }
                        onClick={() => handleTabClick("proposal-page")}
                      >
                        <div className="campaign-detail-btn-tabs">
                          <CustomText
                            p
                            // textColor={"text_color_primary"}
                            fontSize={"font_size_extra_large"}
                            text={"Proposals"}
                          />
                        </div>
                      </div>

                      <div
                        className={
                          activeTab === "contracts-page" ? "active" : ""
                        }
                        onClick={() => handleTabClick("contracts-page")}
                      >
                        <div className="campaign-detail-btn-tabs">
                          <CustomText
                            p
                            // textColor={"text_color_primary"}
                            fontSize={"font_size_extra_large"}
                            text={"Contracts"}
                          />
                        </div>
                      </div>
                      <div
                        className={activeTab === "content-page" ? "active" : ""}
                        onClick={() => handleTabClick("content-page")}
                      >
                        <div className="campaign-detail-btn-tabs">
                          <CustomText
                            p
                            // textColor={"text_color_primary"}
                            fontSize={"font_size_extra_large"}
                            text={"Creatives"}
                          />
                        </div>
                      </div>
                      {campaignDetail?.campaign_data?.type !== "content" && (
                        <div
                          className={
                            activeTab === "analytics-page" ? "active" : ""
                          }
                          onClick={() => handleTabClick("analytics-page")}
                        >
                          <div className="campaign-detail-btn-tabs">
                            <CustomText
                              p
                              // textColor={"text_color_primary"}
                              fontSize={"font_size_extra_large"}
                              text={"Analytics"}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {activeTab === "detail-page" && (
                      <div className="campaign-detail-edit-toggle-btn">
                        {campaignDetail?.campaign_data?.status !==
                          "COMPLETED" &&
                          campaignDetail?.campaign_data?.status !==
                            "IN_REVIEW" && (
                            <Toggle
                              className="custom-toggle"
                              checked={
                                campaignDetail?.campaign_data?.status ===
                                "ACTIVE"
                                  ? true
                                  : false
                              }
                              icons={false}
                              onChange={PublishedAndUnpublished}
                            />
                          )}
                        {campaignDetail?.campaign_data?.status !==
                          "COMPLETED" && (
                          <PrimaryCta
                            sixth
                            textColor={"text_color_light"}
                            iconName={"EditIcon"}
                            fontSize={"font_size_large"}
                            text={"Edit"}
                            onClick={() => openCampaignDetailsModal()}
                          />
                        )}
                      </div>
                    )}
                  </div>
                  {activeTab === "detail-page" && (
                    <CampaignDetailsScreen
                      campaignDetail={campaignDetail}
                      campaignId={campaignId}
                      getCampaignUserDetail={getCampaignUserDetail}
                    />
                  )}

                  <>
                    {" "}
                    {activeTab === "invite-page" && (
                      <InviteList
                        campaignId={campaignId}
                        campaignDetail={campaignDetail}
                      />
                    )}
                  </>

                  {activeTab === "proposal-page" && (
                    <BidListScreen
                      campaignId={campaignId}
                      campaignDetail={campaignDetail}
                    />
                  )}
                  {activeTab === "contracts-page" && (
                    <AssignmentListScreen campaignId={campaignId} />
                  )}
                  {activeTab === "content-page" && (
                    <ContentList campaignId={campaignId} />
                  )}
                  {activeTab === "analytics-page" && (
                    <AnalyticsCampaign
                      campaignId={campaignId}
                      brandId={brand_data.brand_id}
                      orgId={brand_data.brand_id}
                    />
                  )}
                </div>
              </>
            )}
            {modalCampaignDetailsIsOpen && (
              <EditCampaignDetailsScreen
                successCampaignDetail={getCampaignUserDetail}
                campaignId={campaignId}
                closeModal={closeCampaignDetailsModal}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignDetailsParentScreen;
