// Info: Brand Module for Core

import Utils from "../../utils";

export const Brand = {
  // Public functions accessible by other modules

  getBrandId: function (brand_deep_data) {
    return _Brand.getBrandData(brand_deep_data)["brand_id"];
  },

  getBrandName: function (brand_deep_data) {
    return _Brand.getBrandData(brand_deep_data)["name"];
  },

  getBrandOrgId: function (brand_deep_data) {
    return _Brand.getBrandData(brand_deep_data)["org_id"];
  },
}; ////////////////////////////Public Functions END///////////////////////////////

const _Brand = {
  getBrandData: function (brand_deep_data) {
    if (
      !Utils.isNullOrUndefined(brand_deep_data) &&
      !Utils.isNullOrUndefined(brand_deep_data.brand_data)
    ) {
      return brand_deep_data.brand_data;
    }
  },
};
