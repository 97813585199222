"use-strict";

//Dependencies
import React, { useState, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomText,
  Image,
  CustomTextInput,
  PrimaryCta,
  FallBack,
  LinkCta,
  UnderLinedLinkCta,
} from "../../components";
import { isMobile, isIOS } from "react-device-detect";

const DeviceScreen = function (props) {
  return (
    <div className="main-container">
      <div className="fallback">
        <FallBack
          heading="Your account is ready"
          title="Reelax Brands app is not compatible with mobile browser, you can visit brands.getreelax.com on laptop"
        />
        {/* <div className="m-t-15">
          <PrimaryCta
            third
            onClick={() => {
              if (isIOS) {
                window.location.href =
                  "https://apps.apple.com/in/app/reelax-ads-for-brands/id6464460089";
              } else {
                window.location.href =
                  "https://play.google.com/store/apps/details?id=com.reelax.ads";
              }
            }}
            fontSize={"font_size_regular"}
            className="create-campaign"
            text="Download App"
          />
        </div> */}
      </div>
    </div>
  );
};

export default DeviceScreen;
