import React from "react";
import {
  Image,
  CustomText,
  PrimaryCta,
  CustomDropdownOptions,
  BorderedDropdown,
} from "../components";

const OnboardingScreen1 = function (props) {
  var dropdownData = [
    "Brand and Agency",
    "Afganistan",
    "India",
    "Afganistan",
    "India",
    "Afganistan",
    "India",
    "Afganistan",
  ];

  return (
    <div className="main-container">
      <div className="onboard-inner-container">
        <Image source={require("../resources/images/logo.png")} height={80} />

        <div width="40px">
          <CustomText
            h1
            text={"Tell us a bit more to get you started"}
            textColor={"text_color_primary"}
            //fontSize={"font_size_4xl"}
            textAlign={"text_align_center"}
            fontWeight={"rubik_regular"}
          />
        </div>

        <div className="full-width m-t-24">
          <BorderedDropdown name="brand" id="brand">
            <CustomDropdownOptions
              title={"I am"}
              //value = {item}
            />
            {dropdownData.map((item, index) => {
              return <CustomDropdownOptions title={item} value={item} />;
            })}
          </BorderedDropdown>
        </div>
        <div className="full-width m-t-24">
          <BorderedDropdown name="brand" id="brand">
            <CustomDropdownOptions
              title={"I am"}
              //value = {item}
            />
            {dropdownData.map((item, index) => {
              return <CustomDropdownOptions title={item} value={item} />;
            })}
          </BorderedDropdown>
        </div>
        <div className="full-width m-t-24">
          <BorderedDropdown name="brand" id="brand">
            {dropdownData.map((item, index) => {
              return <CustomDropdownOptions title={item} value={item} />;
            })}
          </BorderedDropdown>
        </div>
        <div className="full-width m-t-24">
          <BorderedDropdown name="brand" id="brand">
            {dropdownData.map((item, index) => {
              return <CustomDropdownOptions title={item} value={item} />;
            })}
          </BorderedDropdown>
        </div>
        <div className="full-width m-t-24">
          <BorderedDropdown name="brand" id="brand">
            {dropdownData.map((item, index) => {
              return <CustomDropdownOptions title={item} value={item} />;
            })}
          </BorderedDropdown>
        </div>
        <div className="full-width m-t-24">
          <BorderedDropdown name="brand" id="brand">
            {dropdownData.map((item, index) => {
              return <CustomDropdownOptions title={item} value={item} />;
            })}
          </BorderedDropdown>
        </div>

        <div className="m-t-15 full-width">
          <PrimaryCta
            fullWidth={true}
            text={"Continue"}
            //disabled = {!term_condition || !sent_notification}
            //isLoading ={loading}
            onClick={() => {
              //brandSignup(updateProgress)
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OnboardingScreen1;
