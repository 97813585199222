import Theme from "./themes/light.js";

const Style = {
  // chips: {
  //   color: Theme.colors.FC_LIGHT,
  //   background: Theme.colors.FC_PRIMARY,
  //   borderRadius: 2,
  //   marginBottom: 2,
  // },
  // option: {
  //   background: Theme.colors.FC_LIGHT,
  //   color: Theme.colors.TC_PRIMARY,
  // },
  multiselectContainer: {
    // width: 343,
    // height: 44,
    paddingTop: 0,
    borderRadius: 6,
    cursor: "pointer",
    background: "var(--general-white-color)",
    boxSizing: "content-box",
  },

  multiselectContainerFilter: {
    // width: 343,
    // height: 44,
    paddingTop: 0,
    borderRadius: 6,
    cursor: "pointer",
    background: "var(--general-white-color)",
    boxSizing: "content-box",
    border: "1px solid rgb(230, 231, 232)",
  },

  multiselectContainerEdit: {
    width: 368,
    height: 44,
    paddingTop: 0,
    borderRadius: 4,
    cursor: "pointer",
    background: "var(--general-white-color)",
  },
  multiselectContainerAddress: {
    width: "100%",
    height: 54,
    paddingTop: 0,
    borderRadius: 8,
    cursor: "pointer",
    backgroundColor: "var(--general-white-color)",
    border: "1px solid var(--gray-gray-50)",
  },
  searchBox: {
    // To change search box element look
    border: "none !important",
    fontSize: 10,
    minHeight: 40,

    backgroundColor: "var(--general-white-color)",
    padding: "0px 0px",
  },
  searchBoxAddress: {
    // To change search box element look
    border: "none !important",
    fontSize: 10,
    minHeight: 40,

    backgroundColor: "var(--general-white-color)",
    padding: "0px 0px",
  },
  // searchBox: {
  //   // To change search box element look
  //   border: "none !important",
  //   fontSize: 10,
  //   minHeight: 40,
  // },
  inputFieldAddress: {
    // To change input field position or margin
    marginTop: 12,
    width: 78,
    height: 10,
  },
  chips: {
    // To change css chips(Selected options)
    color: Theme.colors.TC_BACK,
    background: Theme.colors.TC_LIGHT,
    fontSize: Theme.dimensions.LARGE,
    borderRadius: 2,
    marginBottom: 0,
    marginTop: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  chipsAddress: {
    // To change css chips(Selected options)
    color: "var(--gray-gray-500)",
    //background: "var(--general-secondary-color)",
    fontSize: Theme.dimensions.LARGE,
    borderRadius: 2,
    marginBottom: 0,
    marginTop: 7,
    paddingTop: 0,
    paddingBottom: 0,
  },
  optionContainer: {
    // To change css for option container
    border: "2px solid",
  },
  optionContainer: {
    // To change css for option container
    border: "2px solid",
  },
  option: {
    // To change css for dropdown options
    background: Theme.colors.FC_LIGHT,
    color: Theme.colors.TC_TERTIARY,
  },
  optionContainer: {
    height: 200,
  },

  inputField: {
    backgroundColor: "red",
  },

  optionContainerGender: {
    height: 120,
  },

  // multiselectContainer: {
  //   // backgroundColor: "#fff",
  //   // borderRadius: "4px",
  // },
  searchBox: {
    padding: "10px 16px",

    height: 44,
    overflowY: "auto",
    overflowX: "hidden",
    backgroundImage: "url('./assets/images/dropdownIcon.png') ",
  },
  searchBox: {
    padding: "10px 16px",

    backgroundImage: "url('./assets/images/dropdownIcon.png') ",
  },
  searchBox1: {
    padding: "10px 16px",
    width: 343,

    overflowY: "scroll",
    overflowX: "hidden",
    backgroundImage: "url('./assets/images/dropdownIcon.png') ",
  },
  groupHeading: {
    // To chanage group heading style
  },
};

export default Style;
