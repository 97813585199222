// Info: Brand Module for Core

import Utils from "../../utils";

export const Campaign = {
  // Public functions accessible by other modules

  getCampaignInstructionNote: function (campaign_deep_data) {
    if (
      !Utils.isNullOrUndefined(
        _Campaign.getCampaignInstructionData(campaign_deep_data)[
          "instruction_note"
        ]
      )
    ) {
      return _Campaign.getCampaignInstructionData(campaign_deep_data)[
        "instruction_note"
      ];
    }
  },

  getCampaignInstructionDo: function (campaign_deep_data) {
    if (
      !Utils.isNullOrUndefined(
        _Campaign.getCampaignInstructionData(campaign_deep_data)["do"]
      )
    ) {
      return _Campaign.getCampaignInstructionData(campaign_deep_data)["do"];
    }
  },

  getCampaignInstructionDoNot: function (campaign_deep_data) {
    if (
      !Utils.isNullOrUndefined(
        _Campaign.getCampaignInstructionData(campaign_deep_data)["do_not"]
      )
    ) {
      return _Campaign.getCampaignInstructionData(campaign_deep_data)["do_not"];
    }
  },

  getDateString: function (date) {
    let formattedDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;

    var newDate = new Date(formattedDate);

    var unixTimeStamp = Math.floor(newDate.getTime() / 1000);

    return unixTimeStamp;
  },
}; ////////////////////////////Public Functions END///////////////////////////////

const _Campaign = {
  getCampaignInstructionData: function (campaign_deep_data) {
    if (
      !Utils.isEmpty(campaign_deep_data) &&
      //!Utils.isEmpty(campaign_deep_data['instruction_data']) &&
      !Utils.isNullOrUndefined(campaign_deep_data["instruction_data"])
    ) {
      return campaign_deep_data["instruction_data"];
    }
  },
};
